import React from 'react';
import Layout from './Layout';

const NotFoundPage = ({ user, onLogout }) => {
    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="layout-1-col">
                <div className="col-1 single justify-center flex flex-col items-center">
                    <h1>404 - Page Not Found</h1>
                    <p>Sorry, the page you are looking for does not exist.</p>
                </div>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
