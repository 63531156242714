import React, { useState } from 'react';
import Layout from './Layout';
import { register } from '../firebase/FirebaseAuthentication'; // Import the registration function
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

const Register = ({ user, onLogout }) => {
  const [formData, setFormData] = useState({
    displayName: '',
    email: '',
    password: '',
    confirm_password: '',
  });

  const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [serverMessage, setServerMessage] = useState('');

  const onChange = (token) => {
    console.log('Captcha token:', token);
    setCaptchaIsDone(true);
    setRecaptchaToken(token);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData({
      ...formData,
      [name]: name === 'email' ? value.toLowerCase() : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, displayName, confirm_password } = formData;
    try {
      fetch('/server/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, displayName, confirm_password, recaptchaToken }),
      }).then(response => response.json()).then((data) => {
        console.log(data);

        if (data.message) {
          setServerMessage(data.message);
        }

        if(data.redirect) {
          console.log('Redirecting');
          window.location.href = data.redirect;
        }
      });
    } catch (error) {
      // Handle errors, e.g., show error message to the user
      console.error('Registration error:', error);
    }

  };

  return (
    <Layout user={user} onLogout={onLogout}>
      <div class="layout-2-col">
        <div class="col-1">
          <h2>Register for an account</h2>
          <form onSubmit={handleSubmit} className="input-container">

            <label>Display name:</label>
            <input
              type="text"
              name="displayName"
              value={formData.displayName}
              onChange={handleChange}
              required
            />

            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />

            <label>Confirm Password:</label>
            <input
              type="password"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              required
            />

            <ReCAPTCHA
              sitekey={captchaKey}
              onChange={onChange}
            />

            {captchaIsDone && <button type="submit" >Register</button>}

            {serverMessage && <span className='incomplete'>{serverMessage}</span>}

          </form>
        </div>
        <div className='col-2 justify-start'>
          <p>
            <h2>Start your journey</h2>
            <span>Welcome to XP Auction. Here we are committed to giving content creators and their viewers a way to connect with each other beyond the boundries of chat and comment sections.</span>
            <span>Content creation is no easy feat. It requires time, effort and commitment. At XP Auction we want to bridge the gap between the content creators and those who want to accompany them on their journey.</span>
            <span>Whether you are here hoping that your favorite content creator has posted listings here so as you can spend time either playing games with them, or bringing some attention to your own channel, we're here to help.</span>
            <span><strong>Heads up:</strong> If you are a content creator, please register with your business email, or an email that you are comfortable with your auction(s) winners in being provided with to set up a line of communication.</span>
            <span>Register an account with us to start your adventure.</span>
          </p>
        </div>
      </div>
    </Layout>
  );
}

export default Register;
