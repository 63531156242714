import React from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';

const Success = ({ user, onLogout }) => {
    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="layout-1-col">
                <div class="1-col single">
                    <h1>Bid successful!</h1>

                    <p>
                        <span>We have recieved your bid request.</span>
                        <span>We will be in touch soon to confirm whether or not your bid was successful.</span>
                        <span>Please keep an eye on your emails for updates, or check your <Link className='span-link' to={'/account'}>account area</Link> for a breakdown of ongoing and finished bids.</span>
                        <span>We will typically send you an email one (1) hour before the allocated time slot begins to confirm whether or not your bid has been successful. This is noted within an email that we have sent you.</span>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default Success;