import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from './Layout';
import { Link } from 'react-router-dom';

const CategoryTopLevel = ({ user, onLogout, fetchedCategories }) => {

    const categories = fetchedCategories.data;

    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="categories clp p-5">
                <h1>Platforms</h1>
                <p><span>Click one of the platforms below to view available auctions for that platform.</span></p>
                {categories.map(
                    (category) => (
                        <Link to={category.name.toLowerCase().replaceAll(' ', '-')} className="category-name">
                            <div class="underlined">
                               {category.name}
                            </div>
                        </Link>
                    )
                )}

                <a href="#" className="category-name">
                    <div class="underlined">YouTube - Under maintainance</div>
                </a>

                <a href="#" className="category-name">
                    <div class="underlined">Rumble - Coming soon</div>
                </a>

                <a href="#" className="category-name">
                    <div class="underlined">Facebook Gaming - Coming soon</div>
                </a>

                <a href="#" className="category-name">
                    <div class="underlined">TikTok - Coming soon</div>
                </a>
            </div>
        </Layout>
    );
}

export default CategoryTopLevel;