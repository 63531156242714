import React from 'react';
import Layout from './Layout';
import XPAuctionImage from '../assets/XPAuction.png';
import { Link } from 'react-router-dom';

const ToS = ({ user, onLogout }) => {
    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="layout-1-col">
                <div className="col-1 single">
                    <div className='homepage-banner-container'>
                        <img width="100vh" height="auto" className='banner-image' alt="XP Auction" src={XPAuctionImage} />
                    </div>
                </div>
                <div className='col-1 single'>
                    <h1>XP Auction - Terms of Service</h1>
                    <ul>
                        <li>
                            <h2 className='text-left'>1. Introduction</h2>
                            <h3>Purpose of the ToS:</h3>
                            <p>
                                <span>The Terms of Service (ToS) outlined herein are designed to govern your use of XP Auction, an online auction platform for content creators and their audiences. These terms provide a legal framework to ensure a fair, responsible, and enjoyable experience for all users. They set forth the rights, responsibilities, and obligations of all parties involved, including content creators, bidders, buyers, and viewers.</span>

                                <span>By accessing or using XP Auction, you agree to comply with these terms, which are intended to promote transparency, protect intellectual property, ensure user safety, and manage financial transactions effectively. We also outline how personal data is collected, used, and protected in accordance with privacy laws. The ToS cover the specifics of auction creation, bidding processes, content guidelines, payment procedures, dispute resolution, and other relevant aspects of using our platform.</span>

                                <span>We encourage you to read these terms thoroughly to understand your rights and responsibilities as a user of XP Auction. Your continued use of the platform signifies your acceptance of these terms and any updates made to them over time.</span>
                            </p>
                            <h3>Acceptance of Terms:</h3>
                            <p>
                                <span>By accessing or using XP Auction, you acknowledge and agree to be bound by these Terms of Service (ToS). This agreement takes effect immediately upon your use of our platform, and it governs your participation in auctions, content creation, bidding, buying, and all other activities on the platform. If you do not agree to these terms, please do not use our services. It is your responsibility to review these terms periodically for any updates or changes, as your continued use of the platform signifies your acceptance of any amended terms.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>2. Definitions</h2>
                            <h3>Content Creator:</h3>
                            <p>
                                <span>A content creator is an individual whom creates material (content) of an entertaining or educational orientation expressed through online social media platforms such as YouTube, Twitch and other media platforms.</span>

                                <span>The type of content can vary widely, including but not limited to educational material, entertainment content, lifestyle vlogs, tutorials, opinion pieces, and artistic expressions.</span>

                                <span>A significant aspect of being a content creator is engaging with an audience. This can involve responding to comments, tailoring content to audience preferences, or building a community around their content.</span>

                                <span>Content creators are often characterized by their creativity and originality. They bring unique ideas or perspectives to their content, whether it's through storytelling, visual artistry, or informative presentations.</span>

                                <span>By creating an auction listing on XP Auction you agree that you are a content creator, or are starting out as one.</span>
                            </p>
                            <h3>Bidder/Buyer:</h3>
                            <p>
                                <span>XP Auction is a space where content creators (see above) can sell their time and/or services. These services may vary in definition, however our platform is designed with the intention to bring content creators and other likeminded individuals together for networking purposes.</span>
                                <span>A bidder is someone who would bid on the services that content creators create on XP Auction. They would browser for potential content auctions that they'd like to participate in (based on the content auctions title, description and the creator or the auction), add this content auction to their cart and proceed to the checkout to bid on the item.</span>
                            </p>
                            <h3>Auction:</h3>

                            <strong>Each auction created on our platform has:</strong>
                            <ul>
                                <li>
                                    <strong>A title:</strong>
                                    <p>
                                        <span>This is what bidders will see when either using the search functionality or when looking through the platform pages to see content auction listings.</span>
                                    </p>

                                    <strong>A creator:</strong>
                                    <p>
                                        <span>This is the individual / content creator who had made the auction listing.</span>
                                    </p>

                                    <strong>A start time</strong>
                                    <p>
                                        <span>This is the time that the time slot begins for the specified content auction.</span>
                                    </p>

                                    <strong>An end time</strong>
                                    <p>
                                        <span>When the end time is up the content creator is no longer obliged to continue with the activity.</span>
                                    </p>

                                    <strong>A min price (minimum price):</strong>
                                    <p>
                                        <span>This is the minimum price that anyone is allowed to bid on the content auction listing.</span>
                                    </p>

                                    <strong>A max price (maximum price):</strong>
                                    <p>
                                        <span>This works as a buyout price for the first people who bid the maximum price.</span>
                                        <span>When a user bids the maximum price, we log when their bid was placed. If for example there is a content auction listing with a quantity of 1 positions available, the bidder who had first bid the maximum price will be guaranteed that position unless their payment fails.</span>
                                        <span>We process each payment one (1) hour before each content auction listing is due to begin.</span>
                                    </p>

                                    <strong>A quantity:</strong>
                                    <p>
                                        <span>This determines how many positions are available within the time slot for the content auction. For example, if there is a 5 player game there should only be 4 slots listed as available by the content creator (seller). 1 position for the content creator themselves and 4 positions for bidders. The content creator is welcome to list 5 slots in this example, but we advise during auction creation that they inform their bidders that they may be spectating the game and coaching, for example.</span>
                                    </p>

                                    <strong>A description:</strong>
                                    <p>
                                        <span>This is where the content creator (seller) will explain what it is that they plan on doing within the allocated time slot. I.e "Playing League of Legends", "Coaching Fortnite players (I will be spectating you - I will not be playing)", "A podcast about video games"</span>
                                    </p>

                                    <strong>A specified platform:</strong>
                                    <p>
                                        <span>The specified platform that the user is going to be using during their time slot. For example, if a content creator has 1 million subscribers on YouTube, but only 100 followers on Twitch, they should specify which platform they plan on using during the time slot so as the bidder knows exactly what they are bidding on.</span>
                                    </p>
                                </li>
                            </ul>

                            <p>
                                <span>When an auction is created by a content creator, bidders will have the opportunity to bid anything between the minimum and maximum price for the specified time slot.</span>
                                <span>One (1) hour before the specified time slot, the platform (XP Auction) will lock all bids in place and process payments for the winning bidders.</span>
                                <span>The winning bidders will be determined as bidders who bid the maximum price first. For example, if there is a quantity (see above) of 3 for an auction available with a maximum bid of £20 allowed. The first two people to bid £20 will be accepted as the winners (assuming their payments are processed without issue). The third highest bidder will be accepted as the winner of the third time slot available once the bidding is locked on an auction.</span>
                                <span>When any bid is placed, we will send email confirmation of its placement to the bidder.</span>
                                <span>When a bid is won (once bidding is locked) we will send an email confirmation to the winners to inform them of their success and also to provide them with the contact details that the content creator (seller) has provided us with.</span>
                                <span>We will also send an email to the content creator to provide them with the winning bidder(s) contact information.</span>
                                <span>For those who have been unsuccessful in the bidding process, we will send an email confirmation to inform them that no funds have been taken from their account for that auction.</span>
                                <span>Once a service (content auction) has been given by the content creator, bidders will have the option to inform our platform as to whether or not the service as described within the auctions description was delivered within their user account area.</span>
                                <span>If there is a dispute on the side of bidders or content creators, they will be able to contact us via xpauction@gmail.com</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>3. Refunds</h2>
                            <p>
                                <span>Refunds should be considered if the content creator (seller) fails to deliver the content or service as per the agreed terms in the auction.</span>
                                <span>If the delivered content is significantly different from the description provided in the auction listing, a refund may be warranted.</span>
                                <span> If the content creator cancels the auction or fails to fulfill the service without a valid reason, this typically qualifies for a refund.</span>
                                <span>Buyers should report the issue to the platform within 48 hours after the expected delivery date of the content or service.</span>
                                <span>Buyers may be required to provide evidence supporting their claim, such as communication records with the content creator.</span>
                                <span>Upon receiving a refund request, the platform should investigate the claim, which may include reviewing the auction details and communications between the buyer and the seller.</span>
                                <span>Based on the investigation, the platform decides whether a refund is justified.</span>
                                <span>If a refund is granted, the amount may be directly refunded to the buyer’s payment method used for the auction.</span>
                                <span>The refund amount may be deducted from the seller's account balance on the platform, if applicable.</span>
                                <span>In cases where either party disagrees with the refund decision, there should be a clear dispute resolution process.</span>
                                <span>Service fees will not be included within the refund value.</span>
                                <span>We reserve the right to amend the refund policy, notifying users of any significant changes.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>4. User Accounts and Verification</h2>
                            <h3>Account Creation:</h3>
                            <p>
                                <span>For users who want to create auctions or bid on them, everyone will need to create an account on our platform via the registration page and log in before they can either create auctions or bid on existing auctions.</span>
                                <span>Once a user has created an account they will be able to log in via the log in page.</span>
                                <span>Our platform uses Firebase, a state-of-the-art backend service, for account creation and user authentication processes. When you create an account with XP Auction, your account details, including your username and password, are managed securely through Firebase. This integration ensures a seamless and secure user experience, from sign-up to daily access of our platform. Please be assured that your personal information is handled in accordance with our Privacy Policy and Firebase's security standards.</span>
                            </p>
                            <h3>OAuth 2 Verification:</h3>
                            <p>
                                <span>We require our content creators to prove who they say they are to mitigate the risk of scammers on our platform (XP Auction). To do so we have integrated OAuth2 for all third party platforms required for it to function such as YouTube, Twitch and Stripe (Connect).</span>
                                <span>Once a user authenticates with Stripe Connect we will be able to send them the total amount from their top bidders.</span>
                                <span>We take a 40 pence + 4% service charge for every successful bid, per user that wins. We do not charge fees on losing bids.</span>
                            </p>
                            <h3>Stripe Connect:</h3>
                            <p>
                                <span>To receive payments through XP Auction, content creators are required to set up and authenticate a Stripe Connect account. This process involves linking their Stripe Connect account with our platform using Stripe's OAuth authentication system. This ensures secure and efficient payment transactions for all users engaged in our auction services.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>5. Guidelines and Rules for Creating Auctions</h2>

                            <h3>Auction Creation Criteria</h3>
                            <p>
                                <strong>Content Eligibility:</strong><br />
                                <span>We are committed to maintaining a respectful, inclusive, and safe environment for all our users. As such, our platform strictly prohibits any form of hate speech, discrimination, or harassment. This includes, but is not limited to, content or behavior that promotes, glorifies, or condones racism, sexism, ableism, homophobia, transphobia, religious intolerance, or any other form of prejudice based on race, gender, sexual orientation, religion, ethnicity, disability, or any other protected characteristic. Additionally, we do not tolerate bullying, threats, doxxing, or any forms of personal attacks. Violations of these principles will result in appropriate actions, account suspension, or permanent banning, to uphold the safety and dignity of our community.</span>
                            </p>
                            <p>
                                <strong>Creator Eligibility:</strong>
                                <span>Our platform requires content creators to authenticate each platform that they wish to create content auctions for within their account area so as to prove that they are who they say they are. For example, if a user is to set their display name on XP Auction to that of a known streamer we use authentication on Twitch and YouTube proof of identity.</span>
                            </p>

                            <h3>Auction Parameters</h3>
                            <p>
                                <strong>Starting and Minimum Bids:</strong>
                                The minimum bid allowed is £1.
                            </p>
                            <p>
                                <strong>Maximum Price:</strong>
                                <span>The maximum bid allowed is determined by the Stripe payment method provider (stated to be $999,999.99).</span>
                            </p>

                            <p>
                                <strong>Timeframe:</strong>
                                <span>Individuals setting up auctions can set the timeframe to be as long as they desire. We do give a visual reference as to how long that will be when selecting a start date and time. The minimum duration for a content auction is 30 minutes.</span>
                            </p>

                            <h3>Content Description and Transparency</h3>
                            <p>
                                <strong>Clear Descriptions:</strong>
                                <span>Auction descriptions must be clear and specify what the activity or service is that the bidders are bidding on so as to avoid disputes.</span>
                            </p>
                            <p>
                                <strong>Platform Usage:</strong>
                                <span>When using our platform particularly when registering an account and submitting payment information on existing auctions users will have been asked whether they understand and accept these terms of service.</span>
                            </p>

                            <h3>Conduct and Fair Play</h3>
                            <p>
                                <strong>Prohibited Practices:</strong>
                                <span>Members caught exploiting our platform will have the necessary actions made against them, whether it be account suspension, or permanent banning, to uphold the safety and dignity of our community.</span>
                            </p>

                            <p>
                                <strong>Compliance with Laws:</strong>
                                <span>In using XP Auction it is imperative that all users comply with applicable laws and regulations. This includes, but is not limited to, adhering to copyright and intellectual property laws, ensuring that all content is legal and does not infringe upon the rights of others. Users are also responsible for abiding by laws related to online conduct and content, including anti-discrimination statutes and regulations governing online commerce.</span>
                                <span>Failure to comply with these legal obligations can result in serious consequences, both for the user and for our platform, including legal action, suspension of auction privileges, and account termination. We expect our users to exercise due diligence in ensuring that their activities on the platform are lawful and respectful of the rights and safety of others. We reserve the right to review and monitor content for legal compliance and to take appropriate action in the event of any legal violations.</span>
                            </p>

                            <h3>Payment and Fulfillment Terms</h3>
                            <p>
                                <strong>Payment Process:</strong>
                                <span>When a bidder places a bid on an auction, we initiate a payment intent through Stripe Connect. This ensures that funds are securely held and managed in anticipation of the auction's outcome.</span>
                                <span>Approximately one hour before the scheduled start of the content auction, we will confirm or cancel these payment intents. If a bid is successful, the payment will be processed; if the bid is unsuccessful, the payment intent will be canceled, and no charges will be made to the bidder's card.</span>
                                <span>This process is designed to be transparent and secure, providing assurance to both bidders and content creators that the financial aspects of the auction are handled with integrity and efficiency.</span>
                            </p>
                            <p>
                                <strong>Delivery of Services:</strong>
                                <span>Upon the successful conclusion of an auction, we facilitate the exchange of contact information between the winning bidder and the content creator. This step is crucial to establish a line of communication for the fulfillment of the service.</span>
                                <span>The content creator (seller) and the winning bidder(s) are then responsible for coordinating the specifics of the content or service to be provided based on the auctions description. This includes agreeing on the details of the content, delivery method, and any additional requirements.</span>
                                <span>It is the responsibility of both parties to adhere to the agreed terms of the auction and to conduct their interactions in a professional and respectful manner.</span>
                            </p>

                            <h3>Dispute Resolution</h3>
                            <p>
                                <strong>Handling Disputes:</strong>
                                <span>After the conclusion of a content creator's auction time slot, bidders are required to confirm the delivery of the service. This can be done through their account area by selecting either a checkbox (to confirm service delivery) or a cross (to indicate non-delivery or issues with the service).</span>
                                <span>This confirmation is integral to our transparent trust-building system, which allows bidders to view and assess the trustworthiness of content creators based on their history of successful service deliveries. Each user's trust rate is visible, aiding in informed decision-making for future auctions.</span>
                                <span>Users are allowed to submit their confirmation or non-confirmation only once per successful bid to ensure the integrity of the feedback system.</span>
                                <span>In cases where users wish to escalate a dispute or address concerns not covered by the confirmation process, they are encouraged to contact us directly at xpauction@gmail.com. Our team is committed to assisting with dispute resolution, ensuring that all parties are heard and an equitable resolution is reached.</span>
                                <span>We highly advise that both parties gather evidence of their interaction with each other so as to ensure a clear and objective basis for resolving any disputes that may arise. This evidence could include but is not limited to, chat logs, email correspondences, screenshots and/or recordings of interactions, and any other relevant documentation that can substantiate the claims made by either party. Maintaining a record of these interactions not only aids in providing clarity and context when disputes are reviewed but also promotes a transparent and accountable trading environment on our platform. In the event of a dispute, this documented evidence will be crucial in helping XP Auction assess the situation fairly and make an informed decision. We encourage all users to engage in good faith and with a spirit of cooperation, keeping in mind that clear communication and documentation are key to a successful and positive experience on our platform.</span>
                            </p>

                            <h3>Amendments and Updates</h3>
                            <p>
                                <strong>Flexibility to Change:</strong>
                                <span>We recognize that the digital landscape is constantly evolving, and so too must our platform. To maintain the relevance and effectiveness of our services, we reserve the right to make amendments and updates to our policies, terms, and guidelines.</span>
                                <span>Whenever significant changes are made, we will make a concerted effort to notify our users through appropriate channels, such as email notifications, announcements on our platform, or updates to our website.</span>
                                <span>It is the responsibility of our users to stay informed about these changes. We encourage regular review of our policies and terms. Continued use of our platform following any amendments signifies acceptance of the updated terms.</span>
                            </p>

                            <h3>Reporting and Enforcement</h3>
                            <p>
                                <strong>Reporting Mechanism:</strong>
                                <span>To report any violations of our platform's policies or rules, users are encouraged to contact us directly at xpauction@gmail.com. When submitting a report, please provide a detailed description of the issue, including any relevant evidence or documentation, such as screenshots, links, or usernames. This will assist us in investigating and addressing the report more efficiently.</span>
                                <span>Upon receiving a report, our team will initiate a review process to investigate the alleged violation. This may involve examining the evidence provided, consulting with involved parties, and evaluating the situation against our platform's policies.</span>
                            </p>

                            <p>
                                <strong>Enforcement Actions:</strong>
                                <span>In cases where a rule violation is associated with a specific auction, we reserve the right to remove the auction from our platform. This action ensures that all auctions align with our standards and policies.</span>
                                <span>Depending on the severity and frequency of the violations, we may take more stringent actions such as temporary suspension or permanent banning of the user's account. This is to uphold the integrity and safety of our platform.</span>
                                <span>For violations that impact the reliability or trustworthiness of a user, we may reset their trust ratings. This measure is taken to reflect their current standing accurately and maintain transparency for other users.</span>
                                <span>If a violation also constitutes a breach of the Terms of Service of platforms we partner with, such as YouTube or Twitch, we may report the user to these platforms. This step is taken in line with our commitment to upholding community standards across digital spaces.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>6. Payments and Financial Terms</h2>
                            <h3>Payment Processing:</h3>
                            <p>
                                <span>Our platform utilizes Stripe Connect for secure and efficient payment processing. When a bid is successfully placed on an auction, a payment intent is created through Stripe Connect, earmarking the funds for transfer to the seller's account upon auction confirmation.</span>
                                <span>We confirm these payment intents approximately one hour before the content auction's scheduled start time, ensuring that the payment process aligns with the timing of the auctioned service.</span>
                                <span>For every successful bid, we apply a service charge of 40p + 4% of the bid amount. This fee is automatically incorporated into the payment intent and is not charged separately.</span>
                                <span>For instance, on a successful bid of £20, a service fee of £1.03 is applied. In an auction with multiple items, such as 20 items with bids of £20 each, the total service fee would amount to £20.60.</span>
                                <span>The service fee is automatically deducted from the total payment amount at the time of bid confirmation, ensuring a seamless transaction process.</span>
                                <span>After the deduction of the service fee, the remaining amount is transferred to the content creator's account through Stripe Connect. This transfer occurs in line with the confirmation of the auction, ensuring prompt payment to creators.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>7. Privacy and Data Use</h2>
                            <span>For detailed information regarding our data collection, usage, and sharing practices, please refer to our comprehensive <Link to={'/privacy-policy'}>Privacy Policy</Link>. This policy outlines our commitment to protecting your personal information and explains your rights and our responsibilities in detail.</span>
                        </li>
                        <li>
                            <h2 className='text-left'>8. Content and Conduct</h2>
                            <h3>Acceptable Content:</h3>
                            <p>
                                <span>Our platform is dedicated to fostering a positive and creative environment. We permit content that aligns with this ethos, including various forms of entertainment, creativity, and educational material. All content must adhere to our community standards and terms of service.</span>
                                <span>We strictly prohibit any content that is pornographic, violent, or discriminatory in nature. This includes but is not limited to material that promotes hate speech, bigotry, or harm against individuals or groups.</span>
                                <span>All content creators are responsible for ensuring that their content complies with these guidelines. Failure to adhere to these standards may result in suspension, or banning from the platform.</span>
                                <span>Users can report inappropriate content or conduct directly through a report button available on each user's profile and on the auction pages. This feature is designed for quick and easy reporting.</span>
                                <span>Alternatively, users can email us at xpauction@gmail.com to report any concerns or violations. When reporting, please provide specific details and, if possible, evidence of the violation to assist in our review process.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>9. Intellectual Property</h2>
                            <h3>Ownership of Content:</h3>
                            <p>
                                <span>Content created and auctioned through our platform is subject to shared intellectual property rights. The original content creator retains ownership of their intellectual property. However, the successful bidder(s) in the auction are granted specific usage rights as per the terms agreed upon in the auction.</span>
                                <span>Content creators maintain the right to their original work, including the right to be credited for their creation and to use their work for their own purposes, subject to the terms agreed upon in the auction.</span>
                            </p>
                            <h3>Usage Rights:</h3>
                            <p>
                                <span>Upon winning an auction, the bidder(s) are granted the right to use the content as specified in the auction terms. This could include personal use, display, or other forms of utilization as explicitly allowed by the auction agreement.</span>
                                <span>Our platform may retain the right to display, use, or promote the auctioned content for its operational purposes, such as marketing or showcasing the platform's capabilities. This usage is limited and does not imply ownership of the content.</span>
                                <span>All users must respect the intellectual property rights of others. Unauthorized use, reproduction, or distribution of auctioned content is strictly prohibited and may result in legal action, as well as suspension or banning from our platform.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>10. Termination and Suspension</h2>
                            <h3>Account Termination:</h3>
                            <p>
                                <span>An account may be terminated for violations of our platform's terms and policies, including but not limited to, repeated infringement of intellectual property rights, involvement in prohibited activities, or persistent inappropriate behavior. Termination may also occur for legal reasons or due to platform abuse.</span>
                                <span>Before terminating an account, we typically issue warnings or temporary suspensions as corrective measures. However, in severe cases or when legal compliance is at stake, immediate termination may be necessary.</span>
                                <span>Affected users will be notified of the termination and the reasons behind it, except in cases where legal or security concerns prevent such disclosure.</span>
                            </p>
                            <h3>Suspension of Services:</h3>
                            <p>
                                <span>Suspension of services may occur due to non-compliance with our auction rules, payment irregularities, or other breaches of our terms. Suspensions can also be a result of ongoing investigations into alleged misconduct.</span>
                                <span>Suspensions may be temporary and subject to review. Users will be informed about the duration of the suspension and any actions required for the restoration of services.</span>
                                <span>Users have the right to appeal a suspension by emailing xpauction@gmail.com. The appeal process will be conducted fairly, taking into consideration any additional information provided by the user.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>11. Limitation of Liability and Indemnification</h2>
                            <h3>Liability Limitations:</h3>
                            <p>
                                <span>While we strive to provide a high-quality service, XP Auction shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or other intangible losses, resulting from your use of our platform.</span>
                                <span>Our liability is limited to the fullest extent permitted by applicable law. This limitation applies regardless of the nature of the claim, whether in contract, tort (including negligence), product liability, or otherwise.</span>

                            </p>
                            <h3>Indemnification:</h3>
                            <p>
                                <span>You agree to indemnify and hold harmless XP Auction, its officers, directors, employees, and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of our services or your violation of these Terms.</span>
                                <span>In the event of such a claim, we will provide notice of the claim, suit, or action to the contact information we have for your account.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>12. Governing Law and Jurisdiction</h2>
                            <h3>Applicable Law:</h3>
                            <p>
                                <span>These Terms of Service and any contractual or non-contractual disputes arising out of or in relation to these terms will generally be governed by and construed in accordance with the laws of the United Kingdom. However, we acknowledge that certain jurisdictions may apply their own laws, especially in matters concerning consumer rights and taxes.</span>
                                <span>When you use our platform from outside the United Kingdom, local laws may apply to certain aspects of your use, including but not limited to tax obligations and consumer protection rights. While we aim to comply with these local laws to the best of our ability, we also maintain that the overarching framework of our Terms is governed by UK law, except where superseded by those local requirements.</span>
                                <span>We recognize the importance of respecting the legal requirements of each jurisdiction in which we operate. Our commitment is to adhere to those requirements while providing a consistent service to all our users.</span>
                            </p>
                            <h3>Dispute Jurisdiction:</h3>
                            <p>
                                Jurisdiction for any legal disputes.
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>13. Liability Disclaimer for Charity Auctions</h2>
                            <h3>Acknowledgment of User Responsibility</h3>
                            <p>
                                <span>Users of XP Auction ("the platform") acknowledge and understand that charity auctions hosted on the platform involve the sale of services ("content auctions") by individual sellers, and that the decision to donate a portion or all of the proceeds from such auctions to a charitable organization is at the discretion and responsibility of the seller.</span>
                            </p>

                            <h3>Platform's Limited Role</h3>
                            <p>
                                <span>XP Auction acts solely as a platform for facilitating the auction process and does not assume any responsibility for, or make any representations or warranties regarding, the disbursement of funds to charitable organizations.</span>
                            </p>

                            <h3>Seller's Commitment</h3>
                            <p>
                                <span>Sellers are solely responsible for ensuring that all funds raised during the charity auction, as well as any related donations and contributions, are sent to the designated charitable organization(s) within a reasonable timeframe, as defined by the terms and conditions of the charity organization(s).</span>
                            </p>

                            <h3>Disclaimer of Liability</h3>
                            <p>
                                <span>XP Auction disclaims all liability for any disputes, issues, or discrepancies arising from or related to charity auctions and the disbursement of funds to charitable organizations. XP Auction shall not be responsible for:</span>
                            </p>
                            <p>
                                <span>1. The accuracy or truthfulness of the charitable representations made by sellers.</span>
                            </p>
                            <p>
                                <span>2. Any failure by sellers to fulfill their commitments to donate funds to the designated charitable organization(s).</span>
                            </p>
                            <p>
                                <span>3. Any legal or regulatory consequences that may arise due to Sellers' failure to fulfill their commitments.</span>
                            </p>

                            <h3>Reporting Non-Compliance</h3>
                            <p>
                                <span>Users who suspect or encounter instances of charity auction funds not being disbursed to the specified charitable organization(s) should promptly report the issue to XP Auction via xpauction@gmail.com</span>
                            </p>

                            <h3>Changes to Liability Disclaimer</h3>
                            <p>
                                <span>XP Auction reserves the right to update, modify, or revise this liability disclaimer at any time, with or without prior notice to users.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>14. Miscellaneous</h2>
                            <h3>Entire Agreement:</h3>
                            <p>
                                <span>These Terms of Service constitute the entire agreement between XP Auction and its users regarding the use of the platform. They supersede and replace any prior agreements or understandings, whether written or oral, regarding the platform.</span>
                                <span>By using XP Auction, you acknowledge that you have not relied on any statement, promise, representation, assurance, or warranty made by or on behalf of us that is not set out in these terms.</span>

                            </p>
                            <h3>Severability:</h3>
                            <p>
                                <span>If any provision (or part of a provision) of these Terms of Service is found by any court or administrative body of competent jurisdiction to be invalid, unenforceable, or illegal, the other provisions shall remain in effect.</span>
                                <span>If any invalid, unenforceable, or illegal provision would be valid, enforceable, and legal if some part of it were deleted, the provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.</span>
                                <span>This clause ensures that even if one part of the Terms is deemed not legally binding, the rest of the document remains effective and enforceable.</span>
                            </p>
                        </li>
                        <li>
                            <h2 className='text-left'>14. Contact Information</h2>
                            <h3>Contact Details:</h3>
                            <p>
                                xpauction@gmail.com
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </Layout >
    );
}

export default ToS;