import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Link } from 'react-router-dom';

const CheckoutForm = ({ }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isChecked, setIsChecked] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        const termsAndConditions = document.querySelector('[name="terms-and-conditions"]').checked;

        if(!termsAndConditions) {
            setErrorMessage('You must agree to the terms of service before proceeding with your bid');
            return;
        }

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: process.env.REACT_APP_ENVIRONMENT_URL + '/success',
            },
        });


        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <div>

            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <span class="mt-3 block"><input type="checkbox" name="terms-and-conditions" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />I confirm that I have read the <Link to={'/terms-of-service'} className='span-link'>terms of service</Link> and understand my obligations as a bidder on XP Auction.</span>
                <button disabled={!stripe}>Submit</button>
                {/* Show error message to your customers */}
                {errorMessage && <div class="mt-3 incomplete">{errorMessage}</div>}
            </form>

        </div>
    )
};

export default CheckoutForm;