import React, { useState, useEffect } from 'react';
import Layout from './Layout';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Category = ({ user, onLogout, category, fetchedCategories }) => {
    const [products, setProducts] = useState([]);
    const [ascOrDesc, setAscOrSet] = useState(1 * -1);
    const [categories, setCategories] = useState(fetchedCategories);
    const [filterBy, setFilterBy] = useState('');
    const [filter, setFilter] = useState('');

    function formatUnixTimestamp(unix, subtractUnix) {
        var subtractUnix = subtractUnix || 0;

        if (unix.toString().length == 13) {
            unix = unix / 1000;
        }

        if (subtractUnix) {
            return new Date(unix * 1000 - subtractUnix).toLocaleString();
        } else {
            return new Date(unix * 1000).toLocaleString();
        }
    }

    function removeAllClassesFromSiblings(element) {
        element.classList.toggle('sorted');
        const siblings = Array.from(element.parentElement.children);
        siblings.forEach((sibling) => {
            if (sibling !== element) {
                sibling.classList.remove('sorted'); // Remove all classes from the sibling
            }
        });
    }

    const sortBy = async (sortingBy) => {
        try {
            const response = await axios.get('/server/products', {
                params: {
                    type: 'PLP',
                    category: category.name.toLowerCase(),
                    sortingBy: sortingBy,
                    platform: category.name.toLowerCase(),
                    ascOrDesc: ascOrDesc,
                    filter: filter,
                    filterBy: filterBy
                }
            });

            setAscOrSet(ascOrDesc * -1);

            setProducts(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getFilteredList = async (sortingBy) => {
        try {
            const response = await axios.get('/server/products', {
                params: {
                    type: 'PLP',
                    category: category.name.toLowerCase(),
                    sortingBy: sortingBy,
                    platform: category.name.toLowerCase(),
                    ascOrDesc: ascOrDesc,
                    filter: filter,
                    filterBy: filterBy
                }
            });

            setAscOrSet(ascOrDesc);

            setProducts(response.data);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        // var scrollableBars = document.querySelectorAll('.responsive-scroll');

        // // Add a scroll event listener to each element
        // scrollableBars.forEach(function(scrollableBar) {
        //     scrollableBar.addEventListener('scroll', function() {
        //         // Iterate through all elements with class 'responsive-scroll'
        //         scrollableBars.forEach(function(otherScrollableBar) {
        //             // Set scrollLeft for all other elements except the current one
        //             if (otherScrollableBar !== scrollableBar) {
        //                 otherScrollableBar.scrollLeft = scrollableBar.scrollLeft;
        //             }
        //         });
        //     });
        // });


        async function fetchProducts() {
            try {
                const response = await axios.get('/server/products', {
                    params: {
                        type: 'PLP',
                        category: category.name,
                    }
                });

                setProducts(response.data);
            } catch (error) {
                // Handle the error, e.g., show an error message
                console.error(error);
            }
        }
        fetchProducts();
    }, []);

    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="layout-1-col">
                <div className='col-1 single'>
                    <div className='title-logo'>
                        <h1 className='text-center m-0-auto underlined'>{category.name} Content Auctions</h1>
                        <div className={'category-logo mt-4 mb-2 ' + category.name.toLowerCase() + '-logo'}></div>
                    </div>

                    {
                        category.name.toLowerCase() === 'twitch' ? (
                            <p>
                                <span>Twitch is a live streaming platform that is primarily focused on video gaming, including playthroughs of video games, broadcasts of eSports competitions, and other gaming-related events. However, over the years, Twitch has broadened its scope to include streams dedicated to artwork creation, music, talk shows, and the occasional TV series marathon.</span>
                                <span>As a content creator, to start earning money through Twitch, a creator would typically need to grow their channel to meet the requirements of the Affiliate and Partner Programs. This involves building a dedicated audience by having a consistent streaming schedule, engaging content, high-quality stream production, and active interaction with viewers.</span>
                                <span>Making money on Twitch requires time, effort, and a bit of luck. Many streamers stream as a hobby without the intention of making it a primary income source. However, for some, it can become a lucrative career.</span>
                                <span>That's where XP Auction comes in. Below you can find a list of auctions made by Twitch content creators for fans and fellow content creators alike.</span>
                            </p>
                        ) : category.name.toLowerCase() === 'youtube' ? (
                            <p>
                                <span>Earning money as a content creator on YouTube involves building an audience, creating engaging content, and monetizing your channel through various revenue streams.</span>
                                <span>Whether you are here looking for one of your favorite content creators to play video games with, fellow content creators to collaborate with, seeking additional revenue for your channel or a mix of everything, we've got your back.</span>
                                <span>Our platform allows content creators to improve their strategy and use XP Auction to meet new and interesting members of the YouTube community.</span>
                                <span>If you are wanting to dismiss the rift between yourself and a creator that you follow, you can do it all here on our platform.</span>
                            </p>
                        ) : category.name.toLowerCase() === 'rumble' ? (
                            'Rumble desc'
                        ) : category.name.toLowerCase() === 'tiktok' ? (
                            'Tiktok desc'
                        ) : category.name.toLowerCase() === 'facebook gaming' ? (
                            'Facebook gaming desc'
                        ) : (
                            null // You can return null or some default component if none of the conditions are met
                        )
                    }

                    <span>To create your own auction listing on XP Auction, head to your <Link to="/account" className='span-link'>account page</Link> and follow the guidance there.</span>

                    <div>

                        <div class="filter-toolbar input-container">
                            <span>Filter by:</span>
                            <select onChange={(e) => {
                                const newValue = e.target.value;
                                setFilterBy(newValue);
                                getFilteredList();
                            }}>
                                <option name="game">Game</option>
                                <option name="creator">Creator</option>
                                <option name="content">Content</option>
                            </select>

                            <span>Filter value:</span>
                            <input type="text" onChange={(e) => {
                                const newValue = e.target.value;
                                setFilter(newValue);
                                getFilteredList();
                            }} />
                        </div>

                        <p><span class="span-link">Tooltip:</span> Click the arrows to sort listings</p>

                        <div class="overflow-x-scroll mt-4">
                            <div class="xp-listing btn-primary toolbar">
                                <div class="responsive-scroll">
                                    <div onClick={(e) => { sortBy('playing'); removeAllClassesFromSiblings(e.target); }} class="absolute-left first-col">Content:</div>
                                    <div onClick={(e) => { sortBy('creator'); removeAllClassesFromSiblings(e.target); }}>Creator:</div>
                                    <div onClick={(e) => { sortBy('minBid'); removeAllClassesFromSiblings(e.target); }} class='test'>Min bid:</div>
                                    <div onClick={(e) => { sortBy('maxBid'); removeAllClassesFromSiblings(e.target); }} class='test-2'>Buyout price:</div>
                                    {/* <div onClick={() => sortBy('description')}>Description:</div> */}
                                    <div onClick={(e) => { sortBy('starts'); removeAllClassesFromSiblings(e.target); }}>Starts:</div>
                                    <div onClick={(e) => { sortBy('ends'); removeAllClassesFromSiblings(e.target); }}>Bidding Ends:</div>
                                    <div onClick={(e) => { sortBy('created'); removeAllClassesFromSiblings(e.target); }}>Created at:</div>
                                </div>
                            </div>

                            {products.length ? (
                                products.map((product, index) => (
                                    <>
                                        <Link key={product.id || index} to={`/product/${product.id}`} className="btn-primary xp-listing">
                                            <div class="responsive-scroll">
                                                <div>{product.game}</div>
                                                <div>{product.creatorDisplayName}</div>
                                                <div className='test'>{product.minPrice}</div>
                                                <div className='test-2'>{product.maxPrice}</div>
                                                {/* <div>{product.productName}</div> */}
                                                <div>{formatUnixTimestamp(product.dateTime)}</div>
                                                <div>{formatUnixTimestamp(product.dateTime, 3600000)}</div>
                                                <div>{formatUnixTimestamp(product.createdAt)}</div>
                                            </div>
                                        </Link>
                                    </>
                                ))
                            ) : (
                                <>
                                    <div class="no-listings">There are no {category.name} listings yet. Consider creating your own listing by following the guidance <Link to="/account" className='span-link inline-block'>here</Link>.</div>
                                    {/* TODO: Get notified for when new listings are created via email */}
                                    <div class="no-listings">
                                        Other platforms that may have existing listings:
                                        {categories.data.map(category => (
                                            <Link to={'/platforms'}>{category.name}</Link>
                                        ))}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Category;
