import React from 'react';
import Navigation from './Navigation';
import { OverlayProvider } from './OverlayContext';
import Footer from './Footer';
import background from '../assets/animated-background-2.webm';

function Layout({ children, user, onLogout }) {
  return (
    <>
      <video class="bg-vid" autoplay="true" loop="true" muted="true" playsinline>
        <source src={background} type="video/webm" />
      </video>
      <div class="animated-background-shadow">
        <OverlayProvider>
          <Navigation user={user} onLogout={onLogout} /> {/* Pass user and onLogout as props */}
          <div class="content-wrapper">
            {children}
          </div>
          <Footer />
        </OverlayProvider>
      </div>

    </>
  );
}

export default Layout;