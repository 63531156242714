// OverlayContext.js
import React, { createContext, useContext, useState } from 'react';

const OverlayContext = createContext();

export function OverlayProvider({ children }) {
  const [overlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = (active) => {
    debugger;

    if(active == false) {
      console.log('Overlay: ' + active);
      setOverlayVisible(active);
      console.log('Overlay: ' + active);
    } else if (active == true) {
      setOverlayVisible(active);
    }
  };

  return (
    <OverlayContext.Provider value={{ overlayVisible, toggleOverlay }}>
      {children}
    </OverlayContext.Provider>
  );
}

export function useOverlay() {
  return useContext(OverlayContext);
}
