import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Search = ({ }) => {
    const [products, setProducts] = useState([]);
    const [query, setQuery] = useState('');
    const [searchIsActive, setSearchIsActive] = useState(false);

    const toggleSearch = (e, searchLength) => {
        if (searchLength) {
            document.querySelector('.search-overlay').classList.remove('hidden');
            setSearchIsActive(true);
        } else {
            document.querySelector('.search-overlay').classList.add('hidden');
            setSearchIsActive(false);
        }
    };

    // Close the overlay and the search results when clicked
    const searchOverlayClick = () => {
        setSearchIsActive(false); // Hide the minicart-container
        document.querySelector('.search-overlay').classList.add('hidden');
    };


    const searchProducts = async (e) => {
        toggleSearch(e, e.target.value.length);

        setQuery(e.target.value);

        try {
            const response = await axios.get('/server/products', {
                params: {
                    type: 'SEARCH',
                    query: query
                }
            });
            setProducts(response.data);
        } catch (error) {
            // Handle the error, e.g., show an error message
            console.error(error);
        }
    }

    return (
        <>
            <div className="search-container">
                <input type="text" placeholder="Search ..." className="search-input" onChange={(e) => searchProducts(e)} onClick={(e) => searchProducts(e)} />
                <div className={`search-results ${searchIsActive ? "" : "hidden"}`}>
                    {products.length ? (
                        products.map(product => (
                            <Link to={`/product/${product.id}`} className="" key={product.id}>{product.productName}</Link>
                        ))
                    ) : (
                        <>
                            <Link to={`/`}>No products meet this search criteria.</Link>
                        </>
                    )}
                </div>
            </div>
            
            <div className="search-overlay overlay hidden" onClick={searchOverlayClick}></div>
             
        </>

    );
}

export default Search;
