// UserAuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { getAuth, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from 'firebase/auth'; // Import Firebase auth functions

const UserAuthContext = createContext();

export const UserAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const auth = getAuth();

  const login = async (email, password) => {
    try {
      // Use Firebase to authenticate the user
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const authenticatedUser = userCredential.user;
      setUser(authenticatedUser);
    } catch (error) {
        // TODO: Test this with multiple failed login attempts
      console.error('Login error:', error);
      setUser(null); // Clear the user on error
    }
  };

  const logout = async () => {
    try {
      // Sign the user out using Firebase
      await signOut(auth);
      setUser(null); // Clear the user upon logout
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const passwordReset = async (email) => {
    try {
      await sendPasswordResetEmail(email);
    } catch (error) {
      console.error('Send password error:', error);
    }
  }

  return (
    <UserAuthContext.Provider value={{ user, login, logout, passwordReset }}>
      {children}
    </UserAuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(UserAuthContext);
};
