import React, { useEffect, useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Layout from './Layout';
import axios from 'axios'; // Import the Axios library
import { Link } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";

const ProductForm = ({ user, onLogout, fetchedCategories }) => {
    const minStartDate = new Date();
    const [minDate, setMinDate] = useState(new Date());
    const categories = fetchedCategories.data;
    const [showStripeButton, setShowStripeButton] = useState(false);
    const [isActive, setActive] = useState("false");
    const [serverMessage, setServerMessage] = useState('');
    const [duration, setDuration] = useState('');
    const [status, setStatus] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [games, setGames] = useState([]);
    const [gameName, setGameName] = useState('');

    const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

    const recaptchaRef = useRef(null);

    const resetCaptcha = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    };

    const [captchaIsDone, setCaptchaIsDone] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [serverMessageClass, setServerMessageClass] = useState('');

    const onChange = (token) => {
        console.log('Captcha token:', token);
        setCaptchaIsDone(true);
        setRecaptchaToken(token);
    };

    const ToggleClass = () => {
        setActive(!isActive);
    };

    const [productData, setProductData] = useState({
        creator: '',
        productName: '',
        platform: '',
        minPrice: '',
        maxPrice: '',
        dateTime: '',
        endTime: '',
        gamesToPlay: '',
        quantity: '',
        termsAndConditions: '',
        subcategory: '',
        game: '',
        gameSearch: ''
        // Add more fields as needed
    });

    useEffect(() => {
        // if (productData.platform.toLowerCase() === 'twitch') {

        setProductData({
            ...productData,
            'subcategory': 'gaming'
        });
        // }
    }, []);

    const handleGameChange = (e) => {
        setGameName(e.target.getAttribute('data-game'));
        // setProductData({ ...productData, 'game': e.target.getAttribute('data-game') });
        document.querySelector('[name="gameSearch"]').value = e.target.getAttribute('data-game');
        
    }

    const handleChange = (e) => {
        if (e) {
            if (e.target.name == 'platform' && productData.platform.toLocaleLowerCase() == 'twitch') {
                productData.subcategory = 'gaming';
            }
        }

        if(e) {
            if(e.target.name == 'gameSearch') {
                setProductData({...productData, 'gameSearch': e.target.value, 'game': e.target.value })
            }
        }

        if (productData.platform.toLowerCase() == 'twitch' && productData.subcategory == 'gaming' && e.target.name == 'gameSearch') {
            getGames();
        }

        if (productData.subcategory !== 'gaming') {
            setProductData({
                ...productData,
                'game': ''
            });
        }

        const { name, value } = e.target;
        setProductData({
            ...productData,
            [name]: value,
        });

        console.log(productData);
    };

    function formatDuration(durationSeconds) {
        const hours = Math.floor(durationSeconds / 3600);
        const minutes = Math.floor((durationSeconds % 3600) / 60);
        const seconds = durationSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    }

    const handleStartDateChange = (date) => {
        setProductData({
            ...productData,
            dateTime: date, // Update date when the user selects one
        });

        setMinDate(date);
    };

    const handleEndDateChange = (date) => {
        setProductData({
            ...productData,
            endTime: date, // Update date when the user selects one
        });
    };

    const handleClick = (event) => {
        event.target.parentElement.click()
    }

    const getGames = async () => {
        var search = productData.gameSearch;
        try {
            const response = await axios.post('/server/getGames', {
                search
            });

            if (response.data) {
                setGames(response.data);
            } else {
                console.log('No games with that name exist');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const productName = productData.productName;
        const creator = user.email;
        const platform = productData.platform.toLowerCase().replaceAll(' ', '-');
        const dateTime = Math.floor(productData.dateTime / 1000);
        const endTime = Math.floor(productData.endTime / 1000);
        const createdAt = Date.now();
        const minPrice = productData.minPrice;
        const maxPrice = productData.maxPrice;
        const gamesToPlay = productData.gamesToPlay;
        const quantity = productData.quantity;
        const creatorDisplayName = user.displayName;
        const termsAndConditions = document.querySelector('[name="terms-and-conditions"]').checked;
        const subcategory = productData.subcategory;
        var game;

        if(document.querySelector('[name="subcategory"]').value == 'gaming') {
            game = document.querySelector('[name="gameSearch"]').value;
        } else {
            game = document.querySelector('[name="subcategory"]').value;
        }

        const id = productName.toLowerCase().replaceAll(' ', '-');

        // TODO: Ensure that users cannot create products on behalf of others by manipulating `creator` or `id`
        try {
            const response = await axios.post('/server/createProduct', {
                id, productName, platform, creator, dateTime, endTime, createdAt, minPrice, maxPrice, gamesToPlay, quantity, creatorDisplayName, termsAndConditions, recaptchaToken, subcategory, game
            });

            if (response.data.status == 'complete') {
                setStatus(response.data.status);
            } else {
                setStatus('incomplete');
            }

            if (response.data.message) {
                if (response.data.stripeConnectionNeeded) {
                    setShowStripeButton(true);
                }
                setStatus(response.data.type);
                setServerMessage(response.data.message);
            } else {
                console.log('Product created successfully:', response.data);

                if(response.data.redirect) {
                    setTimeout(function() {
                        window.location.href = process.env.REACT_APP_ENVIRONMENT_URL + '/' + response.data.redirect;
                    }, 200)
                }
            }

            resetCaptcha();

        } catch (error) {
            console.error('Error creating product:', error);
        }
    }

    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="layout-2-col">
                <div class="col-1">
                    <form onSubmit={handleSubmit} className="input-container">

                        <input type="text" name="creator" value={user.email} onChange={handleChange} />

                        <label>Auction Title:</label>
                        <input type="text" name="productName" value={productData.productName} onChange={handleChange} />

                        <label>Streaming Platform:</label>
                        <select
                            name="platform"
                            value={productData.platform || ""}
                            onChange={(e) => handleChange(e)}
                        >
                            <option value="">Select a Streaming Platform</option>
                            {categories.map((category) => (
                                <option key={category.name} value={category.name}>
                                    {category.name}
                                </option>
                            ))}
                        </select>

                        {productData.platform.toLowerCase() == 'twitch' && (
                            <>
                                <label>Content type:</label>
                                <select name="subcategory" onChange={handleChange}>
                                    <option value="gaming">Gaming</option>
                                    <option value="chatting">Chatting</option>
                                    <option value="rl_content">RL Content</option>
                                </select>
                            </>
                        )}

                        {productData.platform.toLowerCase() === 'twitch' && productData.subcategory === 'gaming' ? (
                            <>
                                <label>Search for game:</label>
                                <input type="text" name="gameSearch" autoComplete="off" onChange={(e) => handleChange(e)} onKeyDown={() => document.querySelector('.game').classList.add('flex')} onBlur={() => setTimeout(() => { document.querySelector('.game').classList.remove('flex') }, 100)} onClick={() => setTimeout(() => { document.querySelector('.game').classList.add('flex') }, 100)} />

                                <div className="game">
                                    {games.map((game) => (
                                        <span data-game={game.game_name} onClick={(e) => handleGameChange(e)}>
                                            {game.game_name}
                                        </span>
                                    ))
                                    }
                                </div>
                            </>
                        ) : (
                            null
                        )}

                        <label>Description (the content you are willing to cover, i.e:<br />"We will be playing League of Legends and using Discord for voice communication"<br />"Coaching League of Legends on Discord"<br />"A podcast about video games"<br />...</label>
                        <textarea name="gamesToPlay" value={productData.gamesToPlay} onChange={handleChange} />

                        <label>Min Auction Price (£):</label>
                        <input type="number" onWheel={(e) => e.target.blur()} name="minPrice" value={productData.minPrice} onChange={handleChange} />

                        <label>Max Auction Price (optional) (£):</label>
                        <input type="number" onWheel={(e) => e.target.blur()} name="maxPrice" value={productData.maxPrice} onChange={handleChange} />

                        <label>Time Slot (Start Time):</label>

                        <DatePicker
                            selected={productData.dateTime}
                            onChange={handleStartDateChange}
                            showTimeSelect
                            timeIntervals={15}
                            minDate={minStartDate}
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />

                        <label>Time Slot (End Time):</label>

                        <DatePicker
                            selected={productData.endTime}
                            onChange={handleEndDateChange}
                            showTimeSelect
                            timeIntervals={15}
                            minDate={minDate}
                            dateFormat="MMMM d, yyyy h:mm aa"
                        />

                        <label>Quantity to Sell:</label>
                        <input type="number" name="quantity" value={productData.quantity} onChange={handleChange} />

                        <span class="mb-3"><input type="checkbox" name="terms-and-conditions" checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />I confirm that I have read the <Link to={'/terms-of-service'} className='span-link'>terms of service</Link> and understand my obligations as a seller (and/or content creator) on XP Auction.</span>

                        <span className={status == 'complete' ? 'complete' : 'incomplete'}>{serverMessage}</span>
                        {showStripeButton ? (
                            <>
                                <a href={"https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" + process.env.REACT_APP_STRIPE_CA + "&scope=read_write&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + "/account"}>
                                    <button onClick={handleClick} className={`stripe-white ${isActive ? "stripe-white-hover" : ""}`} onMouseEnter={ToggleClass} onMouseLeave={ToggleClass}></button>
                                </a>
                            </>
                        ) : (
                            <>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={captchaKey}
                                    onChange={onChange}
                                />

                                {captchaIsDone && <button type="submit">Create Auction</button>}

                                {serverMessage ?? (
                                    <>
                                        <span className={'message ' + serverMessageClass}>{serverMessage}</span>
                                    </>
                                )}
                            </>
                        )}
                    </form>
                </div>
                <div class="col-2">
                    <p>
                        <h2>Create your content auction</h2>
                        <span>Please read the below information for guidance on each field.</span>
                        <ul className='guidance'>
                            <li>We don't want you to provide an <span class="span-link inline-block m-0">email</span> to your winning bidder(s) that you are uncomfortable in being handing out. Please double-check within this form. If you are not comfortable with this email please <Link className="span-link inline-block m-0" to={'/register'}>register another account</Link> with us.</li>
                            <li>Your <span class="span-link inline-block m-0">display name</span> is your identity as a content creator and how you want to be found on our platform.</li>
                            <li>Set the <span class="span-link inline-block m-0">platform</span> to the platform that you plan on providing your time within. I.e if you have a large following on Twitch people bidding on your content auction would likely want to collaborate / play with you on Twitch, so you should set this to Twitch.</li>
                            <li>The <span class="span-link inline-block m-0">min auction price</span> is the starting price for your content auction.</li>
                            <li>The <span class="span-link inline-block m-0">max price (buyout price)</span> is a buyout price. The first person to successfully place a bid will have secured themselves a place within the timeslot.</li>
                            <li>The <span class="span-link inline-block m-0">time slot (start time)</span> is the time that you will be start doing your named activity. I.e if you are playing Call of Duty with fans, giving advise to smaller content creators. Think of it as a cinema ticket start time (without the trailers).</li>
                            <li>The <span class="span-link inline-block m-0">time slot (end time)</span> is the time that you will finish. Between these times we highly recommend that you gather evidence that your service is being provided in case a bidder files a dispute for not having the service provided.</li>
                            <li>We advise that you are specific when stating <span class="span-link inline-block m-0">the content that you are willing to cover</span> to lower the chance of a dispute from happening.</li>
                            <li>When stating a <span class="span-link inline-block m-0">quantity to sell</span>, this is intended towards any limitations regarding the game and/or communication method that you are going to be playing/using. I.e if you are playing League of Legends, you can only have a maximum party size of 5. Including yourself, that would be a maximum of 4 spots for potential bidders. If you are wanting to bring a friend along (who you don't want to auction your time off to), set it to 3. If we discover that you have set the quantity to 100 for a game of League of Legends (which has a cap of 5 players) then we will refund everyone other than those who you provide evidence for. If you are wanting to coach, or simply spectate and comment on your bidders, please specify that within the auctions description AKA the (`The content that you are willing to cover`) section.</li>
                            <li>That's it. Keep an eye on your emails one hour before your time slot begins!</li>

                        </ul>
                    </p>
                </div>
            </div>
        </Layout>
    );
}

export default ProductForm;