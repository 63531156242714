import React, { useState } from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import { useAuth } from './UserAuthContext';
import ReCAPTCHA from "react-google-recaptcha";
import { getAuth, sendEmailVerification } from 'firebase/auth'; // Import Firebase auth functions

const Login = ({ user, onLogout }) => {
  const { login } = useAuth();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [serverMessage, setServerMessage] = useState('');
  const [serverMessageClass, setServerMessageClass] = useState('');

  const onChange = (token) => {
    console.log('Captcha token:', token);
    setCaptchaIsDone(true);
    setRecaptchaToken(token);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData({
      ...formData,
      [name]: name === 'email' ? value.toLowerCase() : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    try {
      fetch('/server/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ recaptchaToken }),
      }).then(response => response.json()).then(async (data) => {
        console.log(data);

        if (data.message) {
          setServerMessage(data.message);
          setServerMessageClass('success');
        }

        try {
          await login(email, password);

          const auth = getAuth();

          console.log(auth);

          if (auth.currentUser) {
            if (!auth.currentUser.emailVerified) {
              await sendEmailVerification(auth.currentUser)
                .then(() => {
                  console.log('Email verification sent');
                  setTimeout(() => {
                    if (auth.currentUser) {
                      window.location.href = '/account';
                    } else {
                      setServerMessage('Invalid login credentials');
                      setServerMessageClass('incomplete');
                    }
                  }, 1000);
                })
                .catch(error => {
                  setServerMessage('There was an issue sending the verification email');
                });
            }
          }

        } catch (err) {

          if (err) {
            console.log(err);
            setServerMessage(err.message);
            setServerMessageClass('incomplete');
            return;
          }
        }
      });
    } catch (error) {
      // Handle errors, e.g., show error message to the user
      console.error('Registration error:', error);
    }

  };

  return (
    <Layout user={user} onLogout={onLogout}>
      <div class="layout-2-col">
        <div class="col-1">
          <h2>Login</h2>
          <form onSubmit={handleSubmit} className="input-container">

            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label>Password:</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />

            <ReCAPTCHA
              sitekey={captchaKey}
              onChange={onChange}
            />

            {captchaIsDone && <button type="submit">Log in</button>}

            {serverMessage ?? (
              <>
                <span className={'message ' + serverMessageClass}>{serverMessage}</span>
              </>
            )}

            <Link to={'/forgot_password'} className='span-link'>Forgot your password?</Link>
          </form>
        </div>
        <div class="col-2">
          <p>
            <h2>Log in to begin</h2>
            <span>If you are wanting to search for content auctions then simply log in and navigate to the <Link className="span-link" to={'/platforms'}>Platforms / Auctions</Link> page.</span>
            <br />
            <span>Alternatively, if you are wanting to create an auction for your timed service then you will need to log in and navigate to your account area.</span>
            <span>There you will find guidance on how to set up your auctions to generate revenue.</span>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
