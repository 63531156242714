import React, { useEffect, useState, useCallback } from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut, updateProfile } from 'firebase/auth';
import axios from 'axios';

const Account = ({ user, authToken, onLogout }) => {
    const [isActive, setActive] = useState(false);
    const [bids, setBids] = useState([]);
    const [email, setEmail] = useState('');
    const idToken = authToken;
    const [stripeAcct, setStripeAccount] = useState('');
    const auth = getAuth();
    const [bio, setBio] = useState('');
    const [displayName, setDisplayName] = useState(user.displayName);
    const bioId = user.displayName;
    const [bioLink, setBioLink] = useState(user.displayName ? user.displayName.toLowerCase().replace(' ', '-') : '');
    const [twitchUsername, setTwitchUsername] = useState('');
    const [youtubeChannels, setYoutubeChannels] = useState('');
    const [rumbleUsername, setRumbleUsername] = useState('');
    const [tikTokUsername, setTikTokUsername] = useState('');
    const [facebookGamingUsername, setFacebookGamingUsername] = useState('');
    const [lowestWinningBid, setLowestWinningBid] = useState('');

    // Wrap event handlers in useCallback to memoize them
    const handleBioChange = useCallback((e) => {
        setBio(e.target.value);
    }, []);

    const handleDisplayNameChange = useCallback((e) => {
        setDisplayName(e.target.value);
    }, []);

    const getUserBio = (idToken) => { // Just use vanilla JS to make a request, set some variables and use those variables in the inputs. This is a f*ckin joke.
        try {
            fetch('/server/getBio', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idToken, bioId }),
            }).then((response) => response.json()).then((data) => {
                if (data) {
                    if (data.bio) {
                        setBio(data.bio);
                    }
                }
            });
        } catch (err) {
            console.log(err);
        }
    };

    const serviceDelivery = (e, bid, status) => {
        e.preventDefault();

        try {
            fetch('/server/service', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ bid, status }),
            }).then((response) => response.json()).then((data) => {
                alert(data.message);
            });
        } catch (error) {
            console.log(error);
        }
    }

    const updateBio = async (e) => {
        e.preventDefault();

        try {
            fetch('/server/setBio', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ bio, idToken, displayName }),
            }).then((response) => response.json()).then((data) => {
                alert(data.message);
                setBioLink(data.displayName.toLowerCase().replaceAll(' ', '-'));
            });

            await updateProfile(auth.currentUser, {
                displayName: displayName,
            });

        } catch (error) {
            console.log(error);
        }
    };

    const getUserEmail = (idToken) => {
        try {
            axios.post('/server/getUserEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                params: {
                    idToken: idToken,
                },
            }).then((res) => {

                if (res.data.losing_bids) {
                    const mergedBids = res.data.winning_bids.concat(res.data.losing_bids);

                    if (!bids.length) {
                        setBids(mergedBids);
                    }
                }

                if (res.data.twitchUsername) {
                    setTwitchUsername(res.data.twitchUsername);
                }

                if (res.data.youtubeChannels) {
                    setYoutubeChannels(res.data.youtubeChannels);
                }

                console.log('User email:', res.data.email);
                setEmail(res.data.email);

                if (res.data.stripeAcct) {
                    setStripeAccount(res.data.stripeAcct);
                }
            })
        } catch (err) {
            console.log(err);
        }
    };

    const ToggleClass = () => {
        setActive(!isActive);
    };

    const authenticatePlatformUser = () => {
        document.querySelector('.twitch-auth-button').addEventListener('click', () => {
            window.location.href = "https://id.twitch.tv/oauth2/authorize?client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + "/account&response_type=token";
        });

        // document.querySelector('.youtube-auth-button').addEventListener('click', () => {
        //     window.location.href = "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/youtube.readonly&include_granted_scopes=true&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + "/account&response_type=token&client_id=" + process.env.REACT_APP_YOUTUBE_CLIENT_ID;
        // });

        // document.querySelector('.rumble-auth-button').addEventListener('click', () => {
        //     // window.location.href = "https://id.twitch.tv/oauth2/authorize?client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + "&response_type=token";
        // });

        // document.querySelector('.tik-tok-auth-button').addEventListener('click', () => {
        //     // window.location.href = "https://id.twitch.tv/oauth2/authorize?client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + "&response_type=token";
        // });

        // document.querySelector('.facebook-gaming-auth-button').addEventListener('click', () => {
        //     // window.location.href = "https://id.twitch.tv/oauth2/authorize?client_id=" + process.env.REACT_APP_TWITCH_CLIENT_ID + "&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + "&response_type=token";
        // });
    };

    useEffect(() => {
        getUserEmail(idToken);
        getUserBio(idToken);
        authenticatePlatformUser();
    }, []);

    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="layout-2-col">
                <div class="col-1">
                    <>
                        <h1>{user.displayName}'s profile</h1>

                        <div class="account">
                            <div>
                                <form onSubmit={updateBio} className="input-container">
                                    <label for="displayName">Display Name:</label>
                                    <input type="text" id="displayName" onChange={handleDisplayNameChange} value={displayName} />

                                    <label for="bio">Your bio (tell others about yourself):</label>
                                    <input type="textarea" id="bio" onChange={handleBioChange} value={bio} />

                                    <button type="submit">Update my profile</button>
                                </form>
                            </div>

                            <div>
                                <Link to={`/bio/${bioLink.toLowerCase().replaceAll(' ', '-')}`} className="span-link">View your bio</Link>
                            </div>

                            <h2 className='text-left'>Step 1: Connect with Stripe</h2>
                            {stripeAcct ? (
                                <div class="complete">You are connected with Stripe</div>
                            ) : (
                                <>
                                    <p><span class="span-link">Tooltip:</span> If you have just onboarded with Stripe, refresh the page.</p>
                                    <div className='stripe-button-container'>
                                        <a href={"https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" + process.env.REACT_APP_STRIPE_CA + "&scope=read_write&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + '/account'}>
                                            <button className={`stripe-white ${isActive ? "stripe-blue-hover" : ""}`} onMouseLeave={ToggleClass} onMouseEnter={ToggleClass}></button>
                                        </a>
                                    </div>
                                </>
                            )}

                            <h2 className='text-left'>Step 2: OAuth your platform(s)</h2>
                            {twitchUsername ? (
                                <>
                                    <h2 className='text-left'>Twitch username:</h2>
                                    <Link to={'https://twitch.tv/' + twitchUsername}><span class="twitch-auth-button span-link complete">{twitchUsername} authenticated</span></Link>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <span className={'twitch-auth-button span-link incomplete ' + (stripeAcct ? '' : 'no-click')}>Authenticate Twitch</span>
                                    </div>
                                </>
                            )}

                            {/* {youtubeChannels ? (
                                <div>
                                    <h2 className='text-left'>YouTube username(s):</h2>
                                    {youtubeChannels.map(youtubeChannel => (
                                        <div key={youtubeChannel.id}>
                                            <Link to={'https://youtube.com/' + youtubeChannel.link}><span class="twitch-auth-button span-link complete">{youtubeChannel.name} authenticated</span></Link>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div>
                                    <span className={'youtube-auth-button span-link incomplete ' + (stripeAcct ? '' : 'no-click')}>Authenticate YouTube</span>
                                </div>
                            )}

                            <p><span class="span-link">Tooltip:</span> If you have just authenticated a platform, refresh the page.</p> */}

                            <h2 className='text-left'>Step 3: Create content auction(s)</h2>
                            <Link to={'/createAuction'} className='span-link'>Create a content auction</Link>

                            {/* {rumbleUsername ? (
                                <div>Rumble username: {rumbleUsername}</div>
                            ) : (
                                <>
                                    <div>
                                        <span class="rumble-auth-button span-link incomplete">Authenticate Rumble</span>
                                    </div>
                                </>
                            )}

                            {tikTokUsername ? (
                                <div>Tik Tok username: {tikTokUsername}</div>
                            ) : (
                                <>
                                    <div>
                                        <span class="tik-tok-auth-button span-link incomplete">Authenticate Tik Tok</span>
                                    </div>
                                </>
                            )}

                            {facebookGamingUsername ? (
                                <div>Facebook Gaming username: {facebookGamingUsername}</div>
                            ) : (
                                <>
                                    <div>
                                        <span class="facebook-gaming-auth-button span-link incomplete">Authenticate Facebook Gaming</span>
                                    </div>
                                </>
                            )} */}

                            <>
                                {bids.length > 0 ? (
                                    <>
                                        <div class="bids">
                                            <h2 className='text-left'>All Bids:</h2>
                                        </div>
                                        {bids.map(bid => (
                                            <>
                                                <div className={`bid-item ${bid.lowestWinningBid && bid.won === 'ongoing' ? 'losing-bid ' : 'winning-bid '}${bid.won === true ? 'won ' : ''}${bid.won === false ? 'lost ' : ''}${bid.won === 'ongoing' ? 'ongoing ' : ''}`} key={bid.id}>
                                                    <Link to={`/product/${bid.id}`}>
                                                        <>
                                                            <div><strong>{bid.lowestWinningBid && bid.won === 'ongoing' ? 'You are being outbid on this auction.' : ''}{bid.won === false ? 'This auction has finished. Unfortunately you were outbid.' : ''}{bid.won === true ? 'You have won this auction!' : ''}</strong></div>
                                                            <div class="white"><strong>Auction name:</strong> {bid.productName}</div>
                                                            <div class="white"><strong>Sold by:</strong> {bid.creatorName}</div>
                                                            <div class="white"><strong>You bid per item:</strong> £{bid.amount_per_item / 100}</div>
                                                            <div class="white"><strong>Quantity bid for:</strong> {bid.quantity_bid_for}</div>
                                                            <div class="white"><strong>Total bid amount:</strong> £{(bid.amount_per_item / 100) * bid.quantity_bid_for}</div>
                                                            {bid.lowestWinningBid && bid.won === 'ongoing' && (
                                                                <div class="white"><strong>The lowest winning bid per item is currently:</strong> £{bid.lowestWinningBid / 100}</div>
                                                            )}
                                                        </>
                                                    </Link>
                                                    {bid.won !== 'ongoing' && bid.won !== false ? (
                                                        <div class="service-delivery white">
                                                            <span>Was this service provided?</span>
                                                            <button class="service-delivered" onClick={(e) => serviceDelivery(e, bid, 1)}></button>
                                                            <button class="service-not-delivered" onClick={(e) => serviceDelivery(e, bid, 0)}></button>
                                                        </div>
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                            </>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        <h2 className='text-left'>All Bids:</h2>
                                        <div>Either your existing bids have expired, or you have not placed any bids yet.</div>
                                    </>
                                )}
                            </>
                        </div>
                    </>
                </div>
                <div class="col-2">
                    <p>
                        <h2>Start accepting bids</h2>
                        <span>Whilst wanting to make everyone happy, we are also obliged to protect everyones best interests. With this being said, there are a few thing that you will need to keep in mind before creating your first listing, and a few checks that we will need you to complete to prove that you are who you say you are.</span>
                        <ul class="guidance">
                            <li>Your email may be provided to your auctions winners, so ensure that this account is registered under your business email or an alternative email.</li>
                            <li>Keep an eye on your email account an hour before your content auction is due to end. You decide the time when creating your auction. The reason that this is important to remember is because an hour before your auction is due to begin we will stop all bids on your auction and confirm who the winners are, at which point it is your responsibility to reach out to your winners (if necessary) to set up a line of voice and/or text communication with them, i.e Discord, DM's on Twitch/YouTube/etc, emails from the winner(s) themselves, Zoom, Skype, Google Meets.</li>
                            <li>How you communicate (set up comms) with your auctions winner(s) is entirely in your hands as stated in point 2.</li>
                            <li>We need to know that you are who you say you are. You may see a red link above saying 'Authenticate Twitch'. This means that you have not yet authenticated your Twitch account with us (the same goes for other platforms, i.e YouTube etc). Simply click the link and go through the OAuth process (it should take less than a minute to complete per platform).</li>
                            <li>You will need to set up a Stripe Connect account so as we can pay you. Once you have created a Stripe connect account you will need to <Link className="span-link" to={"https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" + process.env.REACT_APP_STRIPE_CA + "&scope=read_write&redirect_uri=" + process.env.REACT_APP_ENVIRONMENT_URL + "/account"}>authenticate</Link> Stripe for XP Auction.</li>
                            <li>We will take a service charge of £0.40 (GBP) + 4% per successful bid. I.e if you have a winning bid on your content of £20, we will take a service charge of £1.20.</li>
                            <li>Now you're ready to <Link className="span-link" to={'/createAuction'}>create a content auction!</Link></li>
                        </ul>
                    </p>
                </div>
            </div>
        </Layout >
    );
};

export default Account;