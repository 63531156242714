import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import XPTwitter from '../assets/x-icon.webp';
import XPFB from '../assets/fb-icon.webp';
import XPReddit from '../assets/reddit-icon.webp';

function Socials() {
    return (
        <>
            <div className='socials-container'>
                <Link to={'https://twitter.com/xpauction'}><img src={XPTwitter}/></Link>
                <Link to={'https://www.facebook.com/XPAuctionFB'}><img src={XPFB}/></Link>
                <Link to={'https://www.reddit.com/user/XPAuction'}><img src={XPReddit}/></Link>
            </div>
        </>
    );
}

export default Socials;