import React, { useState } from 'react';
import Layout from './Layout';
import ReCAPTCHA from "react-google-recaptcha";
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const ForgotPassword = ({ user, onLogout }) => {

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;

  const [captchaIsDone, setCaptchaIsDone] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [serverMessage, setServerMessage] = useState('');
  const [serverMessageClass, setServerMessageClass] = useState('');

  const onChange = (token) => {
    console.log('Captcha token:', token);
    setCaptchaIsDone(true);
    setRecaptchaToken(token);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    setFormData({
      ...formData,
      [name]: name === 'email' ? value.toLowerCase() : value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email } = formData;
    try {
      fetch('/server/forgotPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ recaptchaToken, email }),
      }).then(response => response.json()).then(async (data) => {
        if (data.status == 200) {
          const auth = getAuth();
          sendPasswordResetEmail(auth, email)
            .then(() => {
              // Password reset email sent.
              setServerMessage("Password reset email sent successfully.");
              setServerMessageClass("success");
            })
            .catch((error) => {
              // Error occurred. Inspect error.code and error.message.
              console.error('Error sending password reset email:', error);
              setServerMessage("Failed to send password reset email.");
              setServerMessageClass("error");
            });
        }
      });
    } catch (error) {
      // Handle errors, e.g., show error message to the user
      console.error('Password reset error:', error);
    }

  };

  return (
    <Layout user={user} onLogout={onLogout}>
      <div class="layout-2-col">
        <div class="col-1">
          <h2>Reset your password</h2>
          <form onSubmit={handleSubmit} className="input-container">

            <label>Email:</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <ReCAPTCHA
              sitekey={captchaKey}
              onChange={onChange}
            />

            {captchaIsDone && <button type="submit">Send password reset email</button>}

            {serverMessage ?? (
              <>
                <span className={'message ' + serverMessageClass}>{serverMessage}</span>
              </>
            )}
          </form>
        </div>
        <div class="col-2">
          <p>
            <h2>Forgotten your password?</h2>
            <span>Reset your password here. Enter your email address and then click 'Send reset password email'. If the email exists we will send you an email to reset your password.</span>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default ForgotPassword;
