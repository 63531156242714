// CartContext.js
import React, { useReducer, useContext, createContext, useEffect } from 'react';

const CartContext = createContext();

// Function to add a product to the cart
const addToCart = (dispatch, product, quantity, bid) => {
    // addToCart(product, parseFloat(qtyToBuy), parseFloat(bidAmount));

    dispatch({ type: 'ADD_TO_CART', payload: product, quantity: quantity, bid: bid });
};

// Function to remove a product from the cart
const removeFromCart = (dispatch, productId) => {
    dispatch({ type: 'REMOVE_FROM_CART', productId });
};

const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            // Check if the product already exists in the cart
            const existingProductIndex = state.cart.findIndex(
                (existingProduct) => existingProduct.id === action.payload.id
            );

            const existingProduct = state.cart.find((item) => item.id === action.payload.id);

            if(!action.quantity) {
                alert('You need to add 1 or more of this auction to your cart.');
                return state;
            }

            if(!action.bid) {
                alert('You need to bid a minimum of 1 on this auction.'); // TODO: Add a currency here
                return state;
            }

            if (existingProduct) {
                // Check if the existing product has the same creator
                if (existingProduct.creator !== action.payload.creator) {
                    // Alert when the creator is different
                    alert('You can only purchase from one seller at a time');
                    return state;
                }

                // Update the quantity and price for the existing product
                existingProduct.quantity += action.quantity;
                existingProduct.price += action.quantity * action.bid;

                document.querySelector('.cart-btn').click();

                return {
                    ...state,
                    cart: [...state.cart],
                };
            } else {
                // If the product doesn't exist, add it to the cart
                const price = action.quantity * action.bid;
                const quantity = action.quantity;
                const updatedCart = [...state.cart, { ...action.payload, quantity, price }];

                document.querySelector('.cart-btn').click();

                return {
                    ...state,
                    cart: updatedCart,
                };
            }

        case 'REMOVE_FROM_CART':
            const productIdToRemove = action.productId;

            // Filter out all items with the specified product ID
            const updatedCart = state.cart.filter((product) => product.id !== productIdToRemove);

            localStorage.removeItem('cart');
            
            return {
                ...state,
                cart: updatedCart,
            };

        default:
            if (!state.cart.length) {
                state.cart = JSON.parse(localStorage.getItem('cart'));
            }

            return state;
    }
};


const CartProvider = ({ children }) => {
    const [cartState, dispatch] = useReducer(cartReducer, { cart: [] });

    useEffect(() => {
        // Retrieve cart data from local storage when the component mounts
        const storedCart = localStorage.getItem('cart');
        if (storedCart) {
            dispatch({ type: 'LOAD_CART', payload: JSON.parse(storedCart) });
        }
    }, []);

    useEffect(() => {
        if (cartState.cart.length) {
            // Store cart data in local storage whenever the cart state changes
            localStorage.setItem('cart', JSON.stringify(cartState.cart));
        }
    }, [cartState.cart]);


    return (
        <CartContext.Provider value={{ cartState, addToCart: (product, quantity, bid) => addToCart(dispatch, product, quantity, bid), removeFromCart: (productId) => removeFromCart(dispatch, productId) }}>
            {children}
        </CartContext.Provider>
    );
};


const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
        throw new Error('useCart must be used within a CartProvider');
    }
    return context;
};

export { CartProvider, useCart, addToCart, removeFromCart };
