import React, { useState } from 'react';
import { useCart } from './CartContext';
import { Link } from 'react-router-dom';
import x from '../assets/x.svg';

function Cart() {
  const { cartState, removeFromCart } = useCart();
  const [isCartActive, setCartIsActive] = useState(false);

  const toggleCart = () => {
    document.querySelector('.cart-overlay').classList.toggle('hidden');
    setCartIsActive(!isCartActive);
  };

  return (
    <>
      <button className='btn-primary cart-btn relative underlined' onClick={toggleCart}>
        Your Cart
      </button>
      <div className={`minicart-container ${isCartActive ? "" : "hidden"}`}>
        <img className='cart-close' src={x} onClick={toggleCart} />
        <ul className='mt-3 mb-3'>
          {cartState.cart.length ? (
            cartState.cart.map((product) => (
              <>
                <li key={product.id}>
                  <h3>x{product.quantity} {product.productName}</h3>
                  <br />
                  <h3>Bid for x{product.quantity}: £{product.price}</h3>
                  <button className="btn-primary" onClick={() => removeFromCart(product.id)}>
                    Remove
                  </button>
                </li>
              </>
            ))
          ) : (
            <li>Cart is empty</li>
          )}
        </ul>

        <Link to="/checkout" className="btn-primary checkout-button">
          Checkout
        </Link>
      
      </div>
      
      <div className="cart-overlay overlay hidden" onClick={toggleCart}></div>

    </>
  );
}

export default Cart;
