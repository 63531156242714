import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from './Layout';
import axios from 'axios';
import { useCart } from './CartContext'; // Import the useCart hook
import { Link } from 'react-router-dom';

const PDP = ({ user, onLogout, authToken }) => {
    const { productId } = useParams(); // Get the productId from the route params
    const [product, setProduct] = useState(null);
    const { addToCart } = useCart(); // Access the addToCart function from your context
    const [bidAmount, setBidAmount] = useState(null);
    const [qtyToBuy, setQtyToBuy] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0);
    const [bid, setBid] = useState(0);
    const [error, setError] = useState('');
    const [youtubeChannels, setVerifiedYouTube] = useState([]);
    const [twitchUsername, setTwitchUsername] = useState('');

    const handleAddToCart = (e) => {
        if ((product.dateTime * 1000) - 3600000 < Date.now()) {
            setError('Bids have ended on this content auction.')
        }

        if (!user) {
            window.location.href = '/login';
        }

        // console.log(bidAmount);
        // console.log(product.minPrice);

        if (bidAmount < product.minPrice) {
            setError('The minimum bid for this auction is ' + product.minPrice);
            return;
        }

        e.preventDefault();
        addToCart(product, parseFloat(qtyToBuy), parseFloat(bidAmount));
    };

    useEffect(() => {
        async function fetchProductDetails() {
            try {
                const response = await axios.get('/server/products', {
                    params: {
                        type: 'PDP',
                        id: productId,
                        idToken: authToken
                    }
                });

                setProduct(response.data.product[0]);

                console.log(response.data.verified_youtube_channels);

                setVerifiedYouTube(response.data.verified_youtube_channels);
                setTwitchUsername(response.data.twitch_username);

                document.querySelector('.cart-btn').click();
            } catch (error) {
                // Handle the error, e.g., show an error message
                console.error(error);
            }
        }

        fetchProductDetails();
    }, [productId]);

    if (!product) {
        return <div>Loading...</div>;
    }

    var streamStartsAt = new Date(product.dateTime * 1000);
    var bidsEndAt = new Date((product.dateTime * 1000) - 3600000);
    var offerCreatedAt = new Date(product.createdAt);

    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    };

    streamStartsAt = streamStartsAt.toLocaleString(undefined, options);
    bidsEndAt = bidsEndAt.toLocaleString(undefined, options);
    offerCreatedAt = offerCreatedAt.toLocaleString(undefined, options);

    function formatDuration(durationSeconds) {
        const hours = Math.floor(durationSeconds / 3600);
        const minutes = Math.floor((durationSeconds % 3600) / 60);
        const seconds = durationSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    }


    return (
        <Layout user={user} onLogout={onLogout} product={product}>
            <div class="layout-2-col">
                <div class="col-1">
                    <div class="pdp">
                        <div class="pdp-container">
                            <h1>{product.productName}</h1>
                            <div>
                                <span className="bold">Verified {product.platform} channel(s): </span>
                                <p><span className="span-link">IMPORTANT:</span>Check below to see if the user has verified their <span class="inline-p-span capitalize">{product.platform}</span> channel(s) before bidding on this content auction. You can verify their identity by clicking the channel link(s) below:</p>

                                {product.platform === 'youtube' ? (
                                    !youtubeChannels.length ? (
                                        <span class="incomplete">Warning: This user has not yet authenticated any YouTube channel(s) with XP Auction.</span>
                                    ) : (
                                        youtubeChannels.map((youtubeChannel) => (
                                            <Link to={'https://youtube.com/' + youtubeChannel.link} className="span-link"><p>{youtubeChannel.name}</p></Link>
                                        ))
                                    )
                                ) : null}

                                {product.platform === 'twitch' ? (
                                    twitchUsername ? (
                                        <Link to={'https://twitch.tv/' + twitchUsername} className="span-link"><p>{twitchUsername}</p></Link>
                                    ) : (
                                        <span class="incomplete">Warning: This user has not yet authenticated their Twitch channel with XP Auction.</span>
                                    )
                                ) : null}
                            </div>

                            <br />

                            {(product.dateTime * 1000) - 3600000 < Date.now() && (
                                <div><span class="incomplete">Bids have ended on this content auction.</span></div>
                            )}
                            <div><span className="bold">Product created at: </span>{offerCreatedAt}</div>
                            {product.game && (
                                <div><span className="bold">Playing: </span>{product.game}</div>
                            )}
                            <div><span className="bold">Content: </span>{product.gamesToPlay}</div>
                            <div><span className="bold">Duration: </span>{formatDuration(product.duration || 0)}</div>
                            <div><span className="bold">Creator: </span>{product.creatorDisplayName}</div>
                            <div><span className="bold">Description: </span>{product.productName}</div>
                            <div><span className="bold">Bidding ends: </span>{bidsEndAt}</div>
                            <div><span className="bold">Time slot (product) starts at: </span>{streamStartsAt}</div>
                            <div><span className="bold">Platform: </span>{product.platform}</div>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="pdp-container input-container">
                        <div><span className="bold">Min bid: </span><span className="code-highlight">£{product.minPrice}</span></div>
                        <div>
                            <span className="bold">Max bid (buyout price): </span><span className="code-highlight">
                            {product.maxPrice ? (
                                <span>£{product.maxPrice}</span>
                            ) : (
                                <span>£99'999</span>
                            )}
                            </span>
                        </div>

                        <label htmlFor="bidAmount">Your bid: </label>
                        <input
                            onWheel={(e) => e.target.blur()}
                            type="number"
                            id="bidAmount"
                            value={bidAmount || 0}
                            onChange={(e) => {
                                setBidAmount(e.target.value);
                                // Calculate total price and set it in state or use it as needed
                                if (e.target.value < 1) {
                                    e.target.value = 1; // TODO: Currency check this
                                }

                                var bid = e.target.value * qtyToBuy;

                                if (e.target.value * qtyToBuy > (product.maxPrice || 99999) * qtyToBuy) {
                                    bid = (product.maxPrice || 99999) * qtyToBuy;
                                }

                                setBid(bid);
                                setTotalPrice(bid);
                                // You can set the total price in state or use it as needed
                            }}
                        />


                        <div><span className="bold">Available to bid on: </span><span className="code-highlight">{product.quantity_available_fixed}</span></div>

                        <label htmlFor="qtyToBuy">Your bid quantity (to purchase more for friends): </label>
                        <input
                            onWheel={(e) => e.target.blur()}
                            type="number"
                            id="qtyToBuy"
                            value={qtyToBuy || 0}
                            onChange={(e) => {
                                setQtyToBuy(e.target.value);

                                if(e.target.value > product.quantity_available_fixed) {
                                    setQtyToBuy(product.quantity_available_fixed);
                                }
                                // Calculate total price and set it in state or use it as needed
                                var bid = qtyToBuy * bidAmount;

                                if (bid >(product.maxPrice || 99999) * qtyToBuy) {
                                    setBidAmount((product.maxPrice || 99999));
                                    bid = qtyToBuy * (product.maxPrice || 99999);
                                }

                                setBid(bid);
                                setTotalPrice(bid);
                                // You can set the total price in state or use it as needed
                            }}
                        />


                        <div class="price">
                            £{totalPrice}
                        </div>
                        {/* Product display and "Add to Cart" button */}

                        {(product.dateTime * 1000) - 3600000 < Date.now() ? (
                            ''
                        ) : (
                            user ? (
                                <>
                                    <span class="incomplete">{error}</span>
                                    <button type="submit" onClick={(e) => handleAddToCart(e)}>Add to Cart</button>
                                </>
                            ) : (
                                <button type="submit" onClick={(e) => handleAddToCart(e)}>Log in to bid</button>
                            )
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default PDP;
