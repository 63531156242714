import React from 'react';
import Layout from './Layout';
import XPAuctionImage from '../assets/XPAuction.png';
import contentCreatorImg from '../assets/content_creator_network.png';
import contentCreatorAuction from '../assets/content_creator_auction.png';

const Home = ({ user, onLogout }) => {
  return (
    <Layout user={user} onLogout={onLogout}>
      <div class="layout-1-col">
        <div className="col-1 single no-display md:block">
          <div className='homepage-banner-container'>
            <img width="100vh" height="auto" className='banner-image' alt="XP Auction" src={XPAuctionImage} />
          </div>
        </div>
        <div className="col-1 single justify-center flex flex-col">
          <h1 className="text-center underlined m-0-auto">XP Auction Streaming Community</h1>
          <h2 className="text-center">Where time becomes opportunity</h2>
        </div>
      </div>

      <div class="layout-1-col relative">
        <p className='p-5'>
            <h2 className='text-left'>Time is money - Use it wisely</h2>

            <span>We understand that as a content creator, your time is valuable. Our mission is to empower content creators to make the most of their time.</span>

            <span>Most content creators are familiar with the donation button, which allows them to generate some income through major streaming platforms.</span>

            <span>XP Auction offers content creators the opportunity to auction their time to fans or fellow collaborators. Instead of actively searching for collaborations on your own, we provide a service that enables networking at a whole new level.</span>

            <span>Whether you want to engage with your fans or help another content creator grow their community, every user on our platform can auction their time and connect with the highest bidders during time slots they define.</span>

            <span>For instance, if a creator's streaming schedule starts at 7 pm and you'd like to play with fans from 9 pm to 11 pm, XP Auction makes it possible.</span>
          </p>
        <div class="col-1 single background-banner-2"></div>
      </div>

      <div class="layout-1-col relative">
        <p className='p-5'>
          <h2 className='text-left'>Bid for your dream collaberation</h2>
          <span>We currently offer our service on the largest streaming platforms, such as Twitch and YouTube, and have plans to expand time slot bidding to TikTok, Rumble, and Facebook Gaming.</span>

          <span>If you're interested in collaborating with a larger streamer to grow your audience, simply browse our list of auctions and sort by 'followers.' If you find an open bid for a time slot that matches your desired collaboration time, go ahead and place a bid for that slot.</span>

          <span>Once you win your bid, you'll receive an email one hour before the scheduled start time. This email will confirm when you need to be online. It gives you and the content creator you've purchased the auction from the opportunity to set up communication, whether it's on Discord or any other voice chat platform, to prepare for your future adventure.</span>

          <span>Whether you're looking to generate income as a content creator, explore new opportunities for unique content creation, or are just starting out and seeking to make a name for yourself, XP Auction is here for everyone.</span>
        </p>
        <div class="col-1 single background-banner-1"></div>
      </div>
    </Layout>
  );
}

export default Home;