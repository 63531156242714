import React from 'react';
import Layout from './Layout';
import XPAuctionImage from '../assets/XPAuction.png';
import { Link } from 'react-router-dom';

const Privacy = ({ user, onLogout }) => {
    return (
        <Layout user={user} onLogout={onLogout}>
            <div class="layout-1-col">
                <div className="col-1 single">
                    <div className='homepage-banner-container'>
                        <img width="100vh" height="auto" className='banner-image' alt="XP Auction" src={XPAuctionImage} />
                    </div>
                </div>
                <div className='col-1 single'>
                    <h1>XP Auction - Privacy Policy</h1>
                    <ul>
                        <li>
                            <p>Last updated: 12/11/2023</p>
                            <p>Welcome to the Privacy Policy of XP Auction. This policy explains how we handle and protect your personal data when you visit and use our platform.</p>
                        </li>

                        <li>
                            <h3>Information Collection and Use</h3>
                            <ul>
                                <li>Email Address: Used for account creation and communication.</li>
                                <li>Display Name: Your chosen name displayed on the platform.</li>
                                <li>Stripe Payment Intent Numbers: Collected when creating a bid to process payments.</li>
                                <li>Auction Data: Includes titles, creator details, start and end times, minimum and maximum prices, quantity, descriptions, and specified platforms for each auction listing.</li>
                                <li>Stripe Account IDs: Stored for managing transactions.</li>
                                <li>User Bio: Information provided by users about themselves.</li>
                                <li>Bid Status: To track whether bids are successful or unsuccessful and manage payment intents accordingly.</li>
                                <li>Firebear Token: Used for authentication to ensure security and integrity of requests from users.</li>
                            </ul>
                        </li>

                        <li>
                            <h2>Cookies and Tracking Technologies</h2>
                            <p>
                                <strong>Cookies and Tracking Technologies:</strong> Our platform uses cookies and similar tracking technologies for several purposes. Cookies are small data files stored on your device that help improve your experience on our site. For example, we use cookies to store information about items in your cart, ensuring a seamless and personalized shopping experience. This data is sanitized and does not include any personal information about sellers.
                            </p>
                            <p>
                                In addition to cookies, we are integrating Google Analytics 4 (GA4) to better understand user behavior on our site. GA4 helps us track site visits, user engagement, and conversions. This data is invaluable for improving our platform and understanding what resonates with our users. It also assists in measuring the effectiveness of our marketing efforts. We use this information to enhance our services and provide a more tailored user experience.
                            </p>

                            <h3>Other cookies we collect are:</h3>
                            <ul>
                                <li>__stripe_mid</li>
                                <li>__stripe_sid</li>
                            </ul>
                            <p>
                                Please note that you have control over the use of cookies and can adjust your browser settings to manage or disable cookies as you prefer. However, disabling cookies might affect the functionality and features available on our site.
                            </p>

                        </li>

                        <li>
                            <h2>Data Sharing and Disclosure</h2>
                            <p><strong>Third Parties:</strong> We share data with third-party service providers to facilitate our platform's functions. This includes payment processing with Stripe and analytics tracking with Google Analytics. We ensure these parties adhere to strict data protection and privacy standards and use the data solely for the services they provide to us.</p>
                            <p><strong>Legal Requirements:</strong> We may disclose your data when required by law, such as in response to a court order or a legal demand from government authorities. Our priority in such instances is to protect our users' privacy and legal rights while complying with legal obligations.</p>
                        </li>

                        <li>
                            <h2>Data Security</h2>
                            <p>We take the security of your data seriously. Our platform uses industry-standard security measures, including encryption and secure server hosting, to protect your personal information from unauthorized access, alteration, or disclosure. We continuously update and test our security technology to ensure the integrity and confidentiality of your data.</p>
                        </li>

                        <li>
                            <h2>Your Data Protection Rights</h2>
                            <p>You have rights regarding the personal data we hold about you. These include the right to access your data, request its correction or deletion, and object to or restrict its processing. You can exercise these rights by contacting us at xpauction@gmail.com. We will respond to your requests in accordance with applicable data protection laws.</p>
                        </li>

                        <li>
                            <h2>Changes to This Privacy Policy</h2>
                            <p>We may update our Privacy Policy from time to time. We will notify you of any significant changes by posting the new policy on our platform and, where appropriate, by other means such as email. We encourage you to review this policy periodically for any updates.</p>
                        </li>

                        <li>
                            <h2>Contact Us</h2>
                            <p>If you have any questions or concerns about our Privacy Policy, please contact us at: xpauction@gmail.com</p>
                        </li>
                    </ul>
                </div>
            </div>
        </Layout>
    );
}

export default Privacy;