import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Layout from './Layout';
import axios from 'axios';

const Bio = ({ user, onLogout }) => {
    const { bioId } = useParams(); // Get the bio id from the route params
    const [bio, setBio] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [youTubeChannels, setYouTubeChannels] = useState('');
    const [twitchChannel, setTwitchChannel] = useState('');

    useEffect(() => {
        async function fetchUserBio() {
            try {
                fetch('/server/getBio', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ bioId }),
                }).then((response) => response.json()).then((data) => {
                    if (data) {
                        if (data.bio) {
                            setBio(data.bio);
                        }

                        if (data.displayName) {
                            setDisplayName(data.displayName);
                        }

                        if (data.twitch_username) {
                            setTwitchChannel(data.twitch_username);
                        }
                    }
                });

            } catch (error) {
                // Handle the error, e.g., show an error message
                console.error(error);
            }
        }

        fetchUserBio();
    }, []);

    return (
        <Layout user={user} onLogout={onLogout}>
            <div className="layout-1-col">
                <div className="col-1 single">
                    <h1>{displayName}</h1>

                    {twitchChannel || youTubeChannels ? (
                        <><span class="mb-4">{displayName} has authenticated the below channels with XP Auction:</span><br /></>
                    ) : (
                        <><span class="incomplete">{displayName} has not authenticated any of their social media channels with XP Auction yet.</span><br /></>
                    )}
                    
                    <>
                        {twitchChannel ? (
                            <span>Twitch: <Link to={'https://twitch.tv/' + twitchChannel} className='span-link'>{twitchChannel}</Link></span>
                        ) : null}
                    </>

                    {/* {youtubeChannels ? (
                        <div>
                            <h2 className='text-left'>YouTube username(s):</h2>
                            {youtubeChannels.map(youtubeChannel => (
                                <div key={youtubeChannel.id}>
                                    <Link to={'https://youtube.com/' + youtubeChannel.link}><span class="twitch-auth-button span-link complete">{youtubeChannel.name} authenticated</span></Link>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <span className={'youtube-auth-button span-link incomplete ' + (stripeAcct ? '' : 'no-click')}>Authenticate YouTube</span>
                        </div>
                    )} */}
                    <div class="mt-4">{bio}</div>
                </div>
            </div>
        </Layout>
    );
};

export default Bio;
