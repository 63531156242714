import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import Socials from './Socials';

function Footer() {
  const [year, setYear] = useState('');

  useEffect(() => {
    const timestamp = Date.now();
    const currentDate = new Date(timestamp);
    const currentYear = currentDate.getFullYear();

    setYear(currentYear);
  }, []);

  return (
    <>
      <footer className="flex justify-between p-4 relative xp-nav">

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: "rgb(0 0 0 / 96%)", padding: '50px 20px' }}
          buttonStyle={{ color: "#000", background: "gold", fontSize: "14px" }}
          expires={150}
        >
          <span>This website uses cookies to enhance the user experience.{" "}</span>
          <span>You'll need to accept these for XP Auction to fully function.</span>
        </CookieConsent>

        <Socials/>

        <Link to={'/terms-of-service'} className="span-link mt-3 mb-3">Terms of Service</Link>
        <Link to={'/privacy-policy'} className="span-link mb-3">Privacy Policy</Link>

        <span className='mb-3'>© XP Auction Ltd {year}. All Rights Reserved.</span>
      </footer>
    </>
  );
}

export default Footer;