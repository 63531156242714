import React, { useState, useEffect } from 'react';
import CheckoutForm from './CheckoutForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useCart } from './CartContext';
import axios from 'axios';
import Layout from './Layout';
import { Link } from 'react-router-dom';

const Checkout = ({ user, onLogout, totalPrice }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const { cartState, removeFromCart } = useCart();
  const [options, setOptions] = useState(null);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    const auth = getAuth();

    onAuthStateChanged(auth, async (authUser) => {
      try {
        if (authUser) {
          const authenticatedUser = authUser.auth.currentUser;

          if (authenticatedUser) {
            authenticatedUser.getIdToken().then(async (idToken) => {
              // Fetch the clientSecret from the server
              const response = await axios.post('/server/checkoutAuth', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                params: {
                  idToken: idToken,
                  user: user,
                  cartState: cartState,
                },
              });

              if(response.data.error) {
                setServerMessage(response.data.error);
              }

              if(response.data.message) {
                setServerMessage(response.data.error);
              }

              setClientSecret(response.data);
              setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PK_KEY));

              setOptions({
                clientSecret: response.data.client_secret,
                appearance: {
                  theme: 'night',
                  labels: 'floating'
                }
              });
            });
          } else {
            window.location.href = '/login';
          }
        }
      } catch (error) {
        console.error('Error:', error);
      }
    });
  }, [user, cartState]);

  return (
    <Layout user={user} onLogout={onLogout}>
      <div class="layout-2-col">
        <div class="col-1">
          <div class="checkout-container">
            <div className="App">
              {options && stripePromise && (
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm />
                </Elements>
              )}  
            </div>
          </div>
        </div>
        <div className="checkout-review col-2">
          <h2>Review your bids</h2>
          <ul>
            {cartState.cart.length ? (
              cartState.cart.map((product) => (
                <>
                  <li className="item">
                    <h3>x{product.quantity} {product.productName}</h3>
                    <br />
                    <h3>Bid for <Link to={product.id}>x{product.quantity}: ${product.price}</Link></h3>
                    <button className="btn-primary" onClick={() => removeFromCart(product.id)}>
                      Remove
                    </button>
                  </li>
                </>
              ))
            ) : (
            <li className="item">
              <span>You have no items in your cart yet.</span>
            </li>
            )}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Checkout;
