import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { CartProvider } from './CartContext'; // Import the CartProvider

import Home from './Home';
import Register from './Register';
import Login from './Login';
import ProductForm from './ProductForm';
import { UserAuthProvider } from './UserAuthContext';
import PaymentForm from './PaymentForm';
import Account from './Account';
import CategoryTopLevel from './CategoryTopLevel';
import Category from './Category';
import axios from 'axios'; // Import the Axios library
import PDP from './PDP';
import Bio from './Bio';
import Success from './Success';
import ToS from './ToS';
import Privacy from './privacy';
import Checkout from './Checkout';
import NotFoundPage from './NotFoundPage';
import ForgotPassword from './ForgotPassword';

const fetchedCategories = await axios.get('/server/categories', {});

function App() {
  const [user, setUser] = useState(null);
  const [authCompleted, setAuthCompleted] = useState(false);
  const [authToken, setAuthToken] = useState('');
  const captchaKey = process.env.REACT_APP_CAPTCHA_KEY;


  useEffect(() => {
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);

        const authenticatedUser = authUser.auth.currentUser;

        if (authenticatedUser) {
          // User is signed in.
          authenticatedUser.getIdToken()
            .then(async (idToken) => {
              setAuthToken(idToken);
              // You have the user's Firebase Authentication token (idToken) here.

              const params = new URLSearchParams(window.location.search);
              const code = params.get('code');

              console.log('Referrer:');
              console.log(document.referrer);

              var hash = window.location.hash;
              var youtubeAccessToken;
              var twitchAccessToken;

              if (hash) {
                // YouTube access token
                if (window.location.hash.indexOf('youtube.readonly') > -1) {
                  var accessTokenHash = window.location.hash.match(/access_token=([^&]+)/);

                  if (accessTokenHash) {
                    youtubeAccessToken = accessTokenHash[1];
                  }
                } else {
                  // Twitch access token
                  var accessTokenHash = hash.match(/access_token=([^&]+)/);

                  if (accessTokenHash) {
                    twitchAccessToken = accessTokenHash[1];
                  }
                }
              }

              await axios.post('/server/getUserEmail', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                params: {
                  idToken: idToken, code: code, twitchAccessToken, twitchAccessToken, youtubeAccessToken: youtubeAccessToken
              }
              }).then((res) => {
                  console.log('User email:', res.data.email);
                })
                .catch((error) => {
                  console.error('Error:', error);
                });
              // You can send this token to your server for verification.
            })
            .catch((error) => {
              console.error('Error getting user token:', error);
            });
        } else {
          // No user is signed in.
        }
      } else {
        setUser(null);
      }
      setAuthCompleted(true); // Set authCompleted to true once authentication state is determined
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const logout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <UserAuthProvider>
      <CartProvider>
        <Router>
          {authCompleted ? ( // Render the routes only after authCompleted is true
            <Routes>
              <Route path="/" element={<Home user={user} onLogout={logout} />} />
              <Route path="/register" element={<Register user={user} onLogout={logout} />} />
              <Route path="/login" element={<Login user={user} onLogout={logout} />} />
              <Route path="/createAuction" element={<ProtectedRoute user={user} onLogout={logout} path={'createAuction'} fetchedCategories={fetchedCategories} />} />
              <Route path="/checkout" element={<ProtectedRoute user={user} onLogout={logout} path={'checkout'} />} />
              <Route path="/account" element={<ProtectedRoute user={user} onLogout={logout} path={'account'} authToken={authToken} />} />
              <Route path="/platforms" element={<CategoryTopLevel user={user} onLogout={logout} fetchedCategories={fetchedCategories} />} />
              <Route path="/product/:productId" element={<PDP user={user} onLogout={logout} authToken={authToken} />} />
              <Route path="/bio/:bioId" element={<Bio user={user} onLogout={logout} />} />
              <Route path="/success" element={<ProtectedRoute user={user} onLogout={logout} path={'success'} />} />
              <Route path="/terms-of-service" element={<ToS user={user} onLogout={logout} />} />
              <Route path="/privacy-policy" element={<Privacy user={user} onLogout={logout} />} />
              <Route path="forgot_password" element={<ForgotPassword user={user} onLogout={logout} />} />

              {fetchedCategories.data.map(
                (category) => (
                  <Route path={'/platforms/' + category.name.toLowerCase().replaceAll(' ', '-')} element={<Category category={category} user={user} fetchedCategories={fetchedCategories} onLogout={logout} />} />
                )
              )};

              <Route path="*" element={<NotFoundPage user={user} onLogout={logout}/>} />
            </Routes>
          ) : null}
        </Router>
      </CartProvider>
    </UserAuthProvider>
  );
}

function ProtectedRoute({ user, onLogout, path, fetchedCategories, authToken }) {
  const totalPrice = '10.25'; // TODO: Replace this within the Routes to send an actual variable

  if (!user) {
    window.location.href = '/login'; // Use navigate here to redirect if the user is not authenticated
    return null;
  }

  if (path === 'checkout') {
    return (
      <div>
        
          <Checkout user={user} onLogout={onLogout} totalPrice={totalPrice} />
        
      </div>
    );
  } else if (path === 'createAuction') {
    return (
      <div>
        <ProductForm user={user} onLogout={onLogout} fetchedCategories={fetchedCategories} />
      </div>
    );
  } else if (path === 'account' && authToken) {
    return (
      <div>
        <Account user={user} onLogout={onLogout} authToken={authToken} />
      </div>
    );
  } else if (path === 'success') {
    return (
      <div>
        <Success user={user} onLogout={onLogout} />
      </div>
    );
  }
}


export default App;
