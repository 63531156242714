import React from 'react';
import { Link } from 'react-router-dom';
import Cart from './Cart'; // Import the Cart component
import Search from './Search';

function Navigation({ user, onLogout }) {
  return (
    <div className="xp-nav">
      <div class="nav-start">
        <Link to="/" className="logo-container">
          <div className='xp-logo' label="XP Auction"></div>
        </Link>
        <Search />
      </div>
      <div class="nav-links">
        <Link to="/platforms" className="btn-primary underlined">
          Auctions
        </Link>
        {user ? (
          // If the user is logged in, show a Logout link
          <>
            <Link to="/createAuction" className="btn-primary underlined">
              Create Auction
            </Link>
          </>
        ) : (
          // If the user is not logged in, show the Register and Login links
          <>
            <Link to="/register" className="btn-primary underlined">
              Register
            </Link>
            <Link to="/login" className="btn-primary underlined">
              Login
            </Link>
          </>
        )}

        {user ? (
          <>
            <Link to="/account" className="btn-primary underlined">
              Account
            </Link>
            <Cart />
            <button className="btn-primary underlined" onClick={onLogout}>
              Logout
            </button>
          </>
        ) : (
          ''
        )
        }
      </div>
    </div>
  );
}

export default Navigation;